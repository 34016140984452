import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  //메인화면
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  //로그인
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },

  //설정
  {
    path: "/setting",
    name: "Setting",
    component: () =>
      import(/* webpackChunkName: "setting" */ "../views/Setting.vue"),
  },

  //전략설정
  {
    path: "/strategy",
    name: "Strategy",
    component: () =>
      import(/* webpackChunkName: "strategy" */ "../views/Strategy.vue"),
  },
  //프리셋
  {
    path: "/preset",
    name: "Preset",
    component: () =>
      import(/* webpackChunkName: "preset" */ "../views/Preset.vue"),
  },

  //백테스팅
  {
    path: "/backtest",
    name: "Backtest",
    component: () =>
      import(/* webpackChunkName: "backtest" */ "../views/Backtest.vue"),
  },
  //매매결과
  {
    path: "/result",
    name: "Result",
    component: () =>
      import(/* webpackChunkName: "result" */ "../views/Result.vue"),
  },

  //소개
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
]

const router = new VueRouter({
  routes,
  mode: 'history' 
})

export default router
