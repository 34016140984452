//현재 실행 안됨.

//sb  처리 관련 저장소 모듈
export const snackbar = {
  state: {
    sb: {
      act: false,
      msg: "",
      color: "error",
    },
  },
  getters: {},
  mutations: {
    pop(state, d) {
      state.sb.msg = d.msg;
      state.sb.color = d.color;
      state.sb.act = false;

      state.sb.app = true;

      state.sb.position = d.position;
      state.sb.close = d.close;

      if (d.act === undefined) state.sb.act = true;

      if (d.app === undefined) state.sb.app = true;
    },
  },
  actions: {},
};

// 1. 분배기준
// 2. 자동? 수동? 선착순?
// 3. 선착순 부작용
//     아침 9시

//개인 사업자  보험과는 들게,
//높은 배당을 줄 필요가 없다.
//업체에 맡겨야 한다.

//70만원 : 250 -> 180
//중간관리자가 없어서 생기는 문제다.
//정해진 등급별 성과금
//페널티 규칙 위반에 대한
//지켰을때의 동기부여
//욕먹지 않을 상담사 양성 교육

//KT, SK, 삼성
//하청에서도 삼성 전자의 계열사
//삼성에서 짧게 교육하더라도 한달을 이상을 교육하고
//월급 주고 일을 시킨다.
//간단 업무 같은 경우는 2주~3주 실습 후

//
//작게 일했던 곳이 100명
//300~400? 달려드는
//기본급을 줘버리고
//사람을 교육시키는 강사를
//스크립트만들고
//강사 + 관리자
//
