<template>
  <v-container class="home py-4" fluid style="background: #eee">
    <div v-if="false">{{$store.state.userorder}}</div>
    <v-row no-gutters>
      <v-col class="px-2">
        <WidgetWallet />
      </v-col>
      <v-col class="px-2">
        <WidgetStats :title="$t('dashboard_today_profit_count')" :value="$store.state.stats.today_win_count" />
      </v-col>
      <v-col class="px-2">
        <WidgetStats :title="$t('dashboard_today_profit_count')" prefix="" :value="$store.state.stats.today_loss_count" />
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
//import _ from "lodash"


export default {
  name: "Home",

  components: {},
  data: () => ({
    benched: 0,
    switch1: false,
    switch2: false,

    counter: 0,



				array: ['a', 'b', 'c']


  }),
  computed: {
    items() {
      return Array.from(
        {
          length: 30,
        },
        (k, v) => v + 1
      );
    },
    
    today() {
      return this.$moment().format('YYYY-MM-DD');
    },
    
  },
  methods: {
    btn1() {
      this.$store.dispatch("GET_BALANCE");
    },
    countUp() {
      this.counter += 1;
    },
  },
  created() {console.log('lodash',this.$_.isEmpty(null));},
};
</script>
