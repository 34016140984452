<template>
<v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="650"
      style="z-index:1000;"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          v-if="false"
        >
          Open Dialog
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Risk & Security</span>
        </v-card-title>
        <v-card-text>
          <!--
          이 프로그램은 사용자가 원하는 방식의 매매를 할 수 있도록 도움을 주는 프로그램입니다.<br>
          자동매매를 시작하기 전, 위험요소와 주의사항을 꼭 참고해주세요.
          -->
          <!--
<ul style="font-size:75%;margin-top:15px;">
  <li>인터페이스에서 확인되는 모든 자산은 사용자가 등록한 거래소 API를 이용해 제공하는 정보이며 안전성을 보증하지 않습니다. 사용자는 보유한 API정보 정보를 신중히 관리하고 거래해주시기 바랍니다.</li>
  <li>건당 규모, 자산 수량(비율), 사용자화된 환경변수에 따라 사용자가 적용받는 자산 가격이 변동할 수 있습니다.</li>
  <li>급격한 가격 변동이나 사용자화 환경 변수로 인해 사용자가 예상하지 못한 가격,비율로 거래될 수 있으며, 시점의 가격과 크게 차이가 발생하는 경우 일반적으로 자산을 보유했을 때보다 손실이 발생할 수 있습니다.</li>
  <li>표시되는 모든 수치는 예상 정보로, 정보의 적시성, 적합성, 정확성을 보장하거나 보증하지 않습니다.</li>
  <li>무조건 적인 수익을 보장하지 않으며, 매매에 대한 판단과 책임은 사용자 본인에게 있습니다.</li>
  <li>실행되는 전략 및 데이터는 프로그램 개선 및 서비스 실황에 반영될 수 있습니다.</li>
</ul>-->
<div v-html="$t('global_agreement_txt1')">
</div>
<div v-html="$t('global_agreement_txt2')">
</div>
          


        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-2"
            color="grey darken-1"
            text
            @click="buttonClose"
          >
            {{$t('global_agreement_exit')}}
          </v-btn>
          <v-btn class="mb-2"
            color="primary"
            
            @click="buttonOk"
          >
            {{$t('global_agreement_ok')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

//import { ipcRenderer } from "electron";

export default {
  name: "widgetstats",
  title: "widgetstats",
  props: {
    //dialog: {  required: false, default: false },
  },
  components: {},
  data() {
    return {
      dialog:true,
    };
  },
  watch: {},
  computed: {},
  mounted() {
    /*asdf*/

    let agreement = this.$nvl(this.$localData('agreement'),false);
    if(agreement){
      this.dialog = false;
    }else{
      this.dialog = true;
    }


  },
  methods: {
    buttonOk: function () {
      this.$localData('agreement',this.$moment());
      this.dialog = false;
    },
    buttonClose: function () {
      /* Synchronous message emmiter and handler */
      //console.log(ipcRenderer.sendSync('synchronous-message', 'sync ping'))

      /* Blocked for 10 sec!!! as waiting for synchronous-message. */

      /* Async message sender */
      //ipcRenderer.send('asynchronous-message', 'async ping')

      //ipcRenderer.send("close");

      //alert();
      //          this.w.close();
      



    },
  }
};
</script>
<style lang="scss" scoped>
</style>
