import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import common from '../assets/js/common'

Vue.use(Vuex)
Vue.use(VueRouter);
Vue.use(common);
Vue.prototype.$common = common;


import axios from "axios";
Vue.prototype.$axios = axios;

import { dialog } from "./module/dialog"; //다이얼로그 모듈
import { snackbar } from "./module/snackbar"; //스낵바(토스트) 모듈

import ccxt from "ccxt";


import moment from "moment";
import i18next from 'i18next';
if(i18next.locale =='ko'){
  moment.locale("ko");
}
//console.log('lang',"Browser Language = "+navigator.language);    

//언어설정 S


/*
let _thisLang = localStorage.getItem('lang');

if(!_thisLang){

  _thisLang = navigator.language;

  if(_thisLang == 'ko-KR'){
    _thisLang = 'ko';
  }

  
  localStorage.setItem('lang',_thisLang);
  _thisLang = localStorage.getItem('lang');
 
}
*/


window.server_url = 'https://api-kr.crypto25.net';
window.stats_url = 'https://stats.crypto25.net';

//import _ from 'lodash';    
//import moment from "moment";

export default new Vuex.Store({
  modules:{
    dialog,
    snackbar,
  },
  state: {



    //ccxt 다른 페이지에서 this.$store.state.exchange로 가져올 수 있음.
    exchange : null, 

    //내 자산
    //this.$store.state.exchange.fetchBalance()
    balance : null,

    //티커정보
    ticker : null,


    //new 티커정보
    symbol_list : [],
    symbol_list_json : '',
    symbol_focus : 0,


    userinfo : null,

    positionlist : null, //모든 포지션
    poisition : null, //현재트래킹중인포지션

    //기록
    stats : {

    },
    botlog: []
    ,

    //시스템 저장본
    //로컬에 저장되는 기본 설정값
    systemOption : {
      server_url : window.server_url,
      stats_url : window.stats_url,
      
      bot_clear_option : "",
      position_option : "",
      starting_option : 'auto',
      performance_option : '',
      watch_option : 1,

      exchange : 'binance', //거래소
      symbol : 'BTC/USDT', //대상코인
      api : //api키
      {
          apiKey: '',
          secret: '',
      },
      currency : '', //기준재화
      future : true, //선물모드
      testmode : false, //테스트모드
      //candle : '', //기준봉
      // watch : '', //감시주기

    },

    //봇설정 저장
    botOption : {
      long : {
      },
      short : {
      },

    },
    //봇설정 저장
    presetOption : {
      buy : {
      },
      sell : {
      },
    },

    botlist : {
      open:[],
      close:[],
      trail:[],
    },
    serverinfo : {
      notice: "",
      server_full: false,
      status: "",
      version: "",

    },
    systeminfo : {
      version: '',
      arch: '',
      platform: '',
      version_status: 0,      
    },

    marketlist : [],

    ticker_update : null,
    ticker_update_symbol : null,
    bot_update : null,
    position_update : null,


    crontab_puase : false,

    control_data : '',

  },
  mutations: {
    //ccxt exchange를 저장한다.
    //거래소선택, api키, 선물여부, 테스트모드 여부 정보 필요
    SETUP_EXCHANGE: function (state,payload) {

      //console.log('payload.systemOption',payload.systemOption); 
      //console.log('payload.exchange',payload.exchange);  
      state.exchange = payload.exchange; //ccxt
      state.systemOption = payload.systemOption; //systemoption

    },


    SETUP_BOT: function (state,payload) {
      //console.log('SETUP_BOT botOption',payload); 
      state.botOption = payload; //botOption

    },
    SETUP_PRESET: function (state,payload) {
      //console.log('SETUP_PRESET presetOption',payload); 
      state.presetOption = payload; //botOption

    },

    

    //나의 자산을 불러온다.
    UPDATE_BALANCE: function (state,payload) {

      //console.log('UPDATE_BALANCE payload',payload); 
      state.balance = payload;

    },

    //나의 자산을 불러온다.
    UPDATE_TICKER: function (state,payload) {

      //console.log('UPDATE_TICKER payload',payload); 
      state.ticker = payload;

    },

    UPDATE_USERINFO: function (state,payload) {

      state.userinfo = payload;
      //console.log('UPDATE_USERINFO payload',payload); 

    },

    UPDATE_USERORDER: function (state,payload) {

      state.userorder = payload;
      //console.log('UPDATE_USERORDER payload',payload); 

    },
    UPDATE_USERTRADE: function (state,payload) {

      state.usertrade = payload;
      //console.log('UPDATE_USERTRADE payload',payload); 

    },
    UPDATE_POSITION: function (state,payload) {

      let _position = payload;
      //_position = _.filter(_position,function(item) { return item.data.size > 0  }); //자산있는것만 찾음
        //console.log('_position store ',_position);


        //포지션 pnl per 계산방식변경을 위해서는 이부분 변경필요. 계산방식 정확하지 않아 보류 0517
      /*
      payload.forEach(function (item,index) {
        if(item.data.size > 0 && item.data._ticker_close){
          console.log('-------------------------------');
          console.log('item',index,item.data.symbol,item.data.side,item.data._ticker_close);
          //const initial_margin = (parseFloat(item.data.size) * parseFloat(item.data.entry_price)) / parseFloat(item.data.leverage);
          const initial_margin = (parseFloat(item.data.size) * parseFloat(item.data.entry_price)) / parseFloat(item.data.leverage);
            console.log('initial_margin',initial_margin);

            const fee_to_close = parseFloat(item.data.liq_price) * parseFloat(item.data.size) *0.06;
            const position_margin = parseFloat(initial_margin) + parseFloat(item.data.occ_closing_fee);
            console.log('position_margin',position_margin);
            console.log('item.data.occ_closing_fee',item.data.occ_closing_fee);
            console.log('fee_to_close',fee_to_close);

            console.log(`Initial margin = (Qty x Entry price) / leverage = (${item.data.size} x ${item.data.entry_price}) / ${item.data.leverage} = 140 USDT`);
            console.log(`Fee to close = Bankruptcy price x Qty x 0.06% = ${item.data.liq_price} x ${item.data.size} x 0.06% = 0.756 USDT`,item.data.occ_closing_fee);
            console.log(`Unrealized P&L% = [ ${item.data.unrealised_pnl} USDT / ( ${initial_margin} USDT + ${fee_to_close} USDT ) ] x 100% = ${(item.data.unrealised_pnl/position_margin)*100}`);
            _position[index].data._unrealised_pnl_percent = (item.data.unrealised_pnl/position_margin)*100;


        }
      });
*/

      /*
      const _position_buy_index = self.$_.findIndex(payload, function(props) { return props.data.symbol == _trade_symbol && props.data.side == 'Buy'});
      const _position_sell_index = self.$_.findIndex(payload, function(props) { return props.data.symbol == _trade_symbol && props.data.side == 'Sell'});


      const initial_margin_buy = (_position[_position_buy_index].data.size * _trade_item.price) / _position[_position_buy_index].data.leverage;
      const position_margin_buy = initial_margin_buy + _position[_position_buy_index].data.occ_closing_fee;
      const initial_margin_sell = (_position[_position_sell_index].data.size * _trade_item.price) / _position[_position_sell_index].data.leverage;
      const position_margin_sell = initial_margin_sell + _position[_position_sell_index].data.occ_closing_fee;


      _position[_position_buy_index].data._unrealised_pnl_percent = (_position[_position_buy_index].data.unrealised_pnl/position_margin_buy) * 100;
      _position[_position_sell_index].data._unrealised_pnl_percent = (_position[_position_sell_index].data.unrealised_pnl/position_margin_sell) * 100;
*/





      state.position = _position;
      //console.log('UPDATE_POSITION payload',payload); 

    },
    UPDATE_BOT_LIST: function (state,payload) {

      state.botlist = payload;
      //console.log('UPDATE_BOT_LIST payload',payload); 

    },

    UPDATE_STATS: function (state,payload) {

      state.stats = payload;
      //console.log('UPDATE_STATS payload',payload); 

    },
    UPDATE_LOG: function (state,payload) {

      state.botlog = payload;
      //console.log('UPDATE_LOG payload',payload); 

    },
    UPDATE_SYSTEM_INFO: function (state,payload) {

      state.systeminfo = payload;
      //console.log('UPDATE_SYSTEM_INFO payload',payload); 

    },
    UPDATE_SERVER_INFO: function (state,payload) {

      state.serverinfo = payload;
      //console.log('UPDATE_SERVER_INFO payload',payload); 

    },

    UPDATE_MARKET_LIST: function (state,payload) {

      state.marketlist = payload;
      //console.log('UPDATE_SERVER_INFO payload',payload); 

    },


    UPDATE_SYMBOL_LIST: function (state,payload) {

      
      state.symbol_list = payload;
      //state.symbol_list_json = JSON.stringify(payload);
      //console.log('UPDATE_SERVER_INFO payload',payload); 

    },



    NEXT_SYMBOL_TARGET: function (state) {

      state.symbol_focus = state.symbol_focus+1;
      if(state.symbol_focus > self.$store.state.systemOption.symbols.length){
        state.symbol_focus = 0;
      }

    },

  },
  actions: {
    //ccxt exchange 데이터셋을 준비
     UPDATE_SETTINGS: async function (context, payload) {



  
/*
      //api가 검증됐다면
      if(await context.dispatch("VERIFY_APIKEY",payload)){
        console.log('API검증');
      }else{
        console.log('API검증실패');
        return false;
      };
*/


      //현재 store에 ccxt담음
      /* 예시
      let exchange = new ccxt['bybit']({
        enableRateLimit: true,
        options: {
          defaultType: 'future'
        },
      });
      */

      
      let exchange = await new ccxt[payload.exchange]({
        rateLimit: 500, // unified exchange property
        apiKey: payload.api.apiKey,
        secret: payload.api.secret,
        enableRateLimit: true,
        hedgeMode:true,
        options: {
          defaultType: payload.trademode,
          //warnOnFetchOpenOrdersWithoutSymbol:false,
          adjustForTimeDifference: true,  //# exchange-specific option
        },
      });


      if(payload.testmode){
//alert('테스트모드');
        //테스트서버 상태 지정
        await exchange.setSandboxMode(true); // enable sandbox mode
      }else{

//alert('실서버모드');
      }

      let dataObj = {
        systemOption : payload, //옵션값
        exchange : exchange, //ccxt 저장본 api키가 저장되어야 정상작동
      }

      //console.log('dataObj',dataObj);
      //저장위해 커밋
      context.commit("SETUP_EXCHANGE", dataObj);


if(!payload._reInit){
  context.dispatch("GET_MARKET_LIST");
}



      
      return exchange
    },



    GET_BALANCE: async function ({state}) {

      if(state.exchange){
        setTimeout(async() => {
          
        let balanceData =  await state.exchange.fetchBalance();
        //console.log('balanceData',balanceData);
        return this.commit("UPDATE_BALANCE", balanceData); 
        }, 300);
      }
      /*else{
        console.log('state.userinfo',state.exchange)
      }*/
      

      
    },


    GET_TICKER: async function ({state}) {

      if(state.userinfo){

      //await self.$sleep(self.$store.state.exchange.rateLimit); 
      this.commit("UPDATE_TICKER", await state.exchange.fetchTicker (state.systemOption.symbol));
      
      console.log('test',state.exchange);
      }
    },
    
    GET_USERORDER: async function (context,payload) {
      
      if(payload){
        this.commit("UPDATE_USERORDER", payload);    
      }
    },
    GET_USERTRADE: async function (context,payload) {
      
      if(payload){
        this.commit("UPDATE_USERTRADE", payload);    
      }
    },
    GET_BOT_LIST: async function (context,payload) {
      
      //봇리스트를 가져온다.
      if(payload){
        this.commit("UPDATE_BOT_LIST", payload);    
      }
    },

    GET_POSITION: async function ({state},payload) {

      if(payload){
        this.commit("UPDATE_POSITION", payload);        
      }else{
        const UPDATE_POSITION = await state.exchange.fetchPositions();
        this.commit("UPDATE_POSITION",UPDATE_POSITION);
        console.log('UPDATE_POSITION',UPDATE_POSITION)

          

      }
      if(state.userinfo){
      
      //console.log('UPDATE_POSITION',position);
      }
    },


    GET_USERINFO: async function (context,payload) {
      if(payload){
        this.commit("UPDATE_USERINFO", payload); 
        
        //console.log('GET_USERINFO',payload)

      }
    },
    



    UPDATE_BOT: async function (context, payload) {

      //console.log('UPDATE_BOT',payload);
      //저장위해 커밋
      context.commit("SETUP_BOT", payload);


    },
    UPDATE_PRESET: async function (context, payload) {
      //console.log('UPDATE_BOT',payload);
      //저장위해 커밋
      context.commit("SETUP_PRESET", payload);
    },



    VERIFY_APIKEY: async function ({state}) {

      console.log('VERIFY_APIKEY',state);


    },

    


    OPEN_ORDER: async function ({state},payload) {

/*
      if(payload.type == 'auto'){
        const botlist_json = localStorage.getItem('botList-'+payload.symbol);
        let botlist_data = null;
        if(botlist_json){
          botlist_data = JSON.parse(botlist_json);
          let last_item = _.find(botlist_data.open);
          console.log('OPEN_ORDER','auto=',payload.type);
          console.log('OPEN_ORDER','last_item', last_item);
          console.log('OPEN_ORDER','last_item', moment());
        }


          //const limitTime = self.$nvl(parseInt(self.getBotInfo(self.bot_id).limit.time),0); //재주분방지 시간(분)

          //console.log('OPEN_ORDER','limitTime',limitTime);


        return
      }
*/



      let params = 
      {
        
        //margin_type: "isolated",  //isolration 못찾겄다...ㅜㅜ 거래소에서 지정하는걸로......
        //isolated: true, 
        //reduceOnly: true, //가진것만 binance
        //closePosition: true, //가진것만 
        //close_position: true, //가진것만 
        //positionSide : 'LONG',
        //position_side : 'LONG',
      }
      //{'type':'margin', 'is_isolated': true};

      let order_result = null;
      if(payload.side =='sell'){
         order_result = await state.exchange.create_market_sell_order(payload.symbol, payload.amount, params)
      
        console.warn('order_result',order_result)
        console.log(state)
      }
      if(payload.side =='buy'){
         order_result = await state.exchange.create_market_buy_order(payload.symbol, payload.amount,  params)

        console.warn('order_result',order_result)
        console.log(state)
      }
      console.warn('order_result',JSON.stringify(order_result));

      //지정가 매수
      //const order_result = await this.$store.state.exchange.create_market_buy_order(this.getBotInfo(this.bot_id).symbol, 1,this.globalTicker.close,{'execInst':'ParticipateDoNotInitiate'})
  


      order_result.status = 'open_wait';

      return await order_result
    },

    CLOSE_ORDER: async function ({state},payload) {
      console.log('CLOSE_ORDER payload',payload);
      let order_result = null;
      if(payload.side =='buy'){
         order_result = await state.exchange.create_order(
          payload.symbol, 
          'market',//market or limit
          "sell", //buy or sell
          parseFloat(payload.amount), //amount
          undefined, //price
          {
            reduce_only: true, //가진것만 bybit
            //reduceOnly: true, //가진것만 binance
            //closePosition: true, //가진것만 
            //close_position: true, //가진것만 
            //positionSide : 'LONG',
            //position_side : 'LONG',
          }
          )
      
          console.warn('최근 order_result',order_result)
          console.warn('order_result',JSON.stringify(order_result));
        console.log(state)
      }
      if(payload.side =='sell'){
         order_result = await state.exchange.create_order(
          payload.symbol, 
          'market',//market or limit
          "buy", //buy or sell
          parseFloat(payload.amount), //amount
          undefined, //price
          {
            reduce_only: true, //가진것만
          }
          )   


        console.warn('최근 order_result',order_result)
        console.warn('order_result',JSON.stringify(order_result));
        //console.log(state)
      }
      //order_result._open_id = payload.open_id;
      //order_result._is_clear = payload._is_clear;
      //order_result.status = 'close_wait';


      

      return await order_result
    },
    



    GET_MARKET_LIST: async function ({state}) {
      try {

        let marketList = await state.exchange.loadMarkets();
        //console.warn('getMarketsPair',marketList);

        this.commit("UPDATE_MARKET_LIST", marketList);
        //this.$localData('marketList',marketList);


      } catch (error) {
          console.log(error)
      }
      },


  },
})