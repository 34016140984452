<template>


  <v-app :style="showWindow?'':'background:#33333300;width:110px;height:30px;overflow:hidden;position:fixed;left:0;right:0;top:0;bottom:0;z-index:1000;'">


<div :style="preWidget?'background:#333333;width:110px;height:30px;overflow:hidden;position:fixed;left:0;right:0;top:0;bottom:0;z-index:1000;':''"></div>

  

    <v-app-bar v-if="showWindow"  app color="primary" dark style="-webkit-app-region: drag" dense>
      <div>
        <div class="d-flex align-center">
          <div  style="-webkit-app-region: drag;font-family:'SBAggroB';font-size: 18px;font-weight:normal;color:#fff;text-decoration:none;cursor:default;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                                  
">
<v-btn text href="/" style="font-weight:bold;font-size:20px;height:auto;magin:0;padding:0;">
  {{$t('global_app_title')}} <small style="font-size:50%;;font-weight:normal;margin-top:3px;" class="mx-0">TOOLS {{$store.state.systeminfo.version}}</small>
</v-btn>
            
          <!--<small style="font-size:50%;;font-weight:normal;" class="mx-0">TOOLS {{$store.state.systeminfo.version}}</small>-->
          </div>

      

      </div>
      </div>
          <v-btn text  class="ml-2"
          to="/backtest" @click="changeMenu" >
             <v-icon icon left>mdi-chart-timeline-variant-shimmer</v-icon>
           <span>{{$t('gnb_backtest')}}</span>
          <!-- <v-icon>mdi-home</v-icon>
            <span>{{$t('gnb_home')}}</span>-->
          </v-btn>

          <v-btn  text  class="ml-2"
          to="/strategy" @click="changeMenu" >
            <v-icon icon leftt>mdi-certificate-outline</v-icon>
            <span>{{$t('gnb_starategy')}}</span>
          </v-btn>


      <v-spacer></v-spacer>









      <v-btn
       text  class="ml-2"
       to="/setting" @click="changeMenu">
        <v-icon icon>mdi-cog-outline</v-icon>
        환경설정
      </v-btn>

      <div style=" text-align: right" >

        <!--
        <v-btn
          to="/setting"
          class="ml-4 mr-4"
          small
          outlined  style="-webkit-app-region: no-drag"
        >
          <v-icon left>mdi-account-outline</v-icon>
          <span class="ml-2">프로그램</span>
        </v-btn>
        <v-btn
          to="/setting"
          class="ml-4 mr-4"
          small
          outlined  style="-webkit-app-region: no-drag"
        >
          <v-icon left>mdi-account-outline</v-icon>
          <span class="ml-2">백테스트</span>
        </v-btn>
-->


<!--
        <v-btn
        v-if="$store.state.serverinfo.chat_active && $store.state.systemOption.lang=='ko'"
          @click="openChat"
          left
          small
          outlined
          class="ml-2 mr-2"  style="-webkit-app-region: no-drag"
        >
          <span class="ml-2">{{$t('global_openchat')}} <small v-if="$store.state.serverinfo.chat_secret"></small></span>
          <v-icon right>mdi-chat-processing-outline</v-icon>
        </v-btn>


        <v-btn
          @click="openHelp"
          left
          x-large
          icon  style="-webkit-app-region: no-drag"
        >
          <v-icon style="font-size:28px;">mdi-help-circle-outline</v-icon>
        </v-btn>

        <v-btn 
        v-if="false"
          icon
          color="white"
          x-large
          @click="buttonMinimize"   style="-webkit-app-region:  no-drag"
        >
          <v-icon style="font-size:40px;">mdi-menu-down</v-icon>
        </v-btn>
        <v-btn 
          icon
          color="white"
          x-large
          v-if="$store.state.balance"
          @click="buttonMinimize"   style="-webkit-app-region:  no-drag"
        >
          <v-icon style="font-size:30px;" class="mdi-rotate-90">mdi-glass-stange</v-icon>
        </v-btn>


        <v-btn v-if="false"
          icon
          color="white"
          x-large
          @click="buttonClose"   style="-webkit-app-region:  no-drag"
        >
          <v-icon style="font-size:30px;">mdi-close</v-icon>
        </v-btn>
        -->


      </div>
    </v-app-bar>

  <!-- HOME S-->
  <v-container class="home" fluid  
  :style="$route.name == 'Home'&& showWindow ? 'background: #eee;margin-top:65px;margin-bottom:-65px;height:100%;' : 'height:0;width:0;overflow:hidden;padding:0;margin:0;position:absolute;left:-1000px;top:1000px;'">



  </v-container>
  <!-- HOME E-->




    <v-main v-if="$route.name != 'Home'&& showWindow">
      <router-view />
    </v-main>









<Agreement :dialog="true"/>

    <AlertDialog
      v-if="$store.state.dialog.alertDialogToggle"
      :dialog="$store.state.dialog.alertDialogToggle"
      :timeout="$store.state.dialog.alertDialogInfo.timeout"
      :emoji="$store.state.dialog.alertDialogInfo.emoji"
      :title="$store.state.dialog.alertDialogInfo.title"
      :firstLineText="$store.state.dialog.alertDialogInfo.firstLineText"
      :secondLineText="$store.state.dialog.alertDialogInfo.secondLineText"
      :thirdLineText="$store.state.dialog.alertDialogInfo.thirdLineText"
      :textAlign="$store.state.dialog.alertDialogInfo.textAlign"
      :button1Text="$store.state.dialog.alertDialogInfo.button1Text"
      :button1="$store.state.dialog.alertDialogInfo.button1"
      :button1Payload="$store.state.dialog.alertDialogInfo.button1Payload"
      :button1Color="$store.state.dialog.alertDialogInfo.button1Color"
      :button1Class="$store.state.dialog.alertDialogInfo.button1Class"
      :button2Text="$store.state.dialog.alertDialogInfo.button2Text"
      :button2="$store.state.dialog.alertDialogInfo.button2"
      :button2Payload="$store.state.dialog.alertDialogInfo.button2Payload"
      :button2Color="$store.state.dialog.alertDialogInfo.button2Color"
      :button2Class="$store.state.dialog.alertDialogInfo.button2Class"
      :persistent="$store.state.dialog.alertDialogInfo.persistent"
      :maxWidth="$store.state.dialog.alertDialogInfo.maxWidth"
    ></AlertDialog>

    <v-snackbar
      v-model="$store.state.snackbar.sb.act"
      :color="$store.state.snackbar.sb.color"
      :top="$store.state.snackbar.sb.position === 'top'"
      :bottom="$store.state.snackbar.sb.position === 'bottom'"
      :centered="$store.state.snackbar.sb.position === 'center'"
      style="padding: 0"
    >
      <v-row no-gutters>
        <v-col cols="auto" class="mr-auto">
          <v-card class="pa-0" outlined tile color="transparent">
            {{ $store.state.snackbar.sb.msg }}
          </v-card>
        </v-col>
        <v-col cols="auto" v-if="$store.state.snackbar.sb.close">
          <v-card outlined tile color="transparent">
            <v-btn color="grey" @click="$store.commit('pop', { act: false })">
              {{$t('global_snackbar_close')}}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-snackbar>



    <v-bottom-navigation 
    v-if=" showWindow && false"
    
    grow flat app fixed color="primary"  style="-webkit-app-region: no-drag;z-index:100;">
      
      
      <!--
      <v-btn to="/" @click="changeMenu">
        <span>{{$t('gnb_dashboard')}}</span>
        <v-icon class="mdi-rotate-270">mdi-label-outline</v-icon>
      </v-btn>
-->

          <v-btn 
          to="/backtest" @click="changeMenu" >
            <span>{{$t('gnb_backtest')}}</span>
            <v-icon>mdi-chart-timeline-variant-shimmer</v-icon>
          </v-btn>

          <v-btn 
          to="/strategy" @click="changeMenu" >
            <span>{{$t('gnb_starategy')}}</span>
            <v-icon>mdi-certificate-outline</v-icon>
          </v-btn>







      <v-btn to="/setting" @click="changeMenu">
        <span>{{$t('gnb_settings')}}</span>
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>


    

    
  </v-app>
</template>

<script>
//import { ipcRenderer, shell } from "electron";
//import { ipcRenderer, shell } from "electron";
//import { ipcRenderer, shell } from "electron";

//import ccxt from "ccxt";

//const electron = window.require("electron");
/*
  ipcRenderer.on('show_window_return', (event, result) => {
      window.is_show_window = result;
      
      //트레이 기능을 없앰. 
      //window.is_show_window = true; //강제로 보이게 만듬

      //console.log('vis_show_window',window.is_show_window);
  });
  */


export default {
  name: "App",

  data: () => ({
symbol_focus: 0,


windowLoading:true,

    coinItem: null,
    item: null,

    showWindow : true,
    widget_chart: false,

    preWidget : false,
    isRefresh : false,

    symbolInit:false,
    //
  }),
  created() {

/*
    this.$crontab.addJob({
      name: 'show_window',
      interval: {
        seconds: '/1',
      },
      job: this.isShowWindow
    });
*/


    setTimeout(() => {
      this.windowLoading=false;
    }, 500);
    this.goUrl('backtest');


    this.symbolInit = false;
    setTimeout(() => {
        this.symbolInit = true;
    }, 100);
              

              
  },
  mounted(){

  },

  watch: {
    '$store.state.symbol_list'(){
      console.log('asdf')

    },
    '$store.state.symbol_focus'(){



        if(this.widget_chart){

                this.toggleTradeView();
                setTimeout(() => {
                  
                this.toggleTradeView();
                }, 30);

        }


      }
  },

  methods: {
    changeMenu(){
        if(this.$route.name != 'Home'){
            window.closeTradingView();
            this.widget_chart =false;
        }


/*
        if(this.$route.name == 'Home'){
              for (let i = 0; i < localStorage.length; i++) {
                var key = localStorage.key(i);
                //var value = localStorage.getItem(key);
                if(key.includes( 'candleList')){ //캔들도 제외
                  console.log('changeMenu',key);
                  localStorage.removeItem(key);
                }
              }
        }
*/


    },



        toggleTradeView(){
          if(this.$store.state.systemOption.symbols){
                  //this.widget_chart=!this.widget_chart;
                                  
                    const bx_chart = document.getElementById('bx_chart');
                    //const bx_chart_line = document.querySelector('#bx_chart_line');
                    //const bt_close_chart = document.querySelector('#bt_close_chart');

                    
                    
                  if(this.widget_chart){
                    this.widget_chart = null;
                    bx_chart.style.display = 'none';
                    //bt_close_chart.style.display = 'none';
                    //bx_chart_line.style.display = 'none';



                  }else{
                    this.widget_chart = true;
                    bx_chart.style.display = 'block';
                    //bt_close_chart.style.display = 'block';
                    //bx_chart_line.style.display = 'block';

                    let exchange ="BYBIT";
                    let symbol = this.$store.state.systemOption.symbols[this.$store.state.symbol_focus];
                        symbol = symbol.replace("/", '');
                        symbol = symbol.toUpperCase();
                    let inverval ="5";


                      window.openTradingView(exchange,symbol,inverval);
                      

                  }

          }
        },

        
    openChat: function () {
      const self = this;


              let alertDialogInfo = {
                timeout: 0,
                //emoji: "😆",
                width: 800,
                title: self.$t('global_openchat_title'),
                //secondLineText: this.$store.state.serverinfo.chat_notice,
                //secondLineText: `<textarea onclick="this.select();this.setSelectionRange(0, 99999);navigator.clipoard.writeText(this.value);document.getElementById('clipboard_status').style.display='block';" style="width:100%;background:#eee;;height:100px;padding:10px;border-radius:4px;font-size:9px;color:#888;line-height:1;cursor:pointer;">${settings_json}</textarea><small id='clipboard_status' style='display:none;color:black;'>클립보드에 복사되었습니다.</small>`,
                //thirdLineText: "<small>현재의 봇설정 및 현황, 시스템에 설정된  <span style='color:red'>Api/Secret키가 포함되어있습니다. 타인과 절대로 공유하지 마세요.</span></small>",

                button2Text: self.$t('global_openchat_bt_join'), //commit function name
                button2: "externalLink", //commit function name
                button2Payload: this.$store.state.serverinfo.chat_link, //commit function name
                button2Color: "primary",
                button2Class: "",

                button1Text: self.$t('global_openchat_bt_close'), //commit function name
                button1: "close", //commit function name
                button1Payload: null, //commit function name

                button1Color: "",
                button1Class: "",

              };
              
              if(this.$store.state.serverinfo.chat_notice){
                  Object.assign(alertDialogInfo, {
                    firstLineText : this.$store.state.serverinfo.chat_notice
                  })
              }

              if(this.$store.state.serverinfo.chat_secret){
                  Object.assign(alertDialogInfo, {
                    secondLineText: self.$t('global_openchat_join_code')+this.$store.state.serverinfo.chat_secret
                  })
              }


              this.$store.dispatch("openAlertDialog", alertDialogInfo);


      //shell.openExternal("https://open.kakao.com/o/gJ6YonUd");



    },
    openHelp: function () {
    let _thisLang = this.$localData('lang');
      if(_thisLang == 'ko'){
      window.open("https://crypto25.notion.site/crypto25/1a2457fb74b8451699f40ffb8e21dac6", '_blank').focus();
      }else{
      window.open("https://crypto25.notion.site/Getting-Started-Crypto-25-9fc8878c509747b5804235bfe13746e5", '_blank').focus();
      }


       //shell.openExternal(this.$store.state.serverinfo.url_app_banner_guide);
/*
    let _thisLang = this.$localData('lang');
    if(!_thisLang){
      _thisLang = this.$localData('lang',navigator.language);
    }

      if(_thisLang == 'ko'){
         shell.openExternal("https://crypto25.notion.site/crypto25/1a2457fb74b8451699f40ffb8e21dac6");       
      }else{
         shell.openExternal("https://crypto25.notion.site/Getting-Started-Crypto-25-9fc8878c509747b5804235bfe13746e5");
      }
*/



    },
    buttonMinimize: function () {
      this.preWidget = true;
      setTimeout(() => {
      this.preWidget = false;
        
      }, 2000);
      
      //ipcRenderer.send("minimize");
      //미니마이즈가 아니라 화면에서 숨겨주기만 한ㅏ. 0x0

/*
            let alertDialogInfo = {
              timeout: 2000,
              persistent:true,
              //emoji: "😆",
              title: "위젯 구동 중...",
              //firstLineText: "실행중인 프로그램을 종료합니다.",
              secondLineText: "화면을 정리 중입니다.",
              // thirdLineText: "셋째줄",
              };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);
*/


      //ipcRenderer.send("minimize_virtual");



                    const bx_chart = document.getElementById('bx_chart');
                    this.widget_chart = null;
                    bx_chart.style.display = 'none';

    },
    buttonRestore: function () {
      const self = this;


      //ipcRenderer.send("minimize");
      //미니마이즈가 아니라 화면에서 숨겨주기만 한ㅏ. 0x0
            let alertDialogInfo = {
              timeout: 2000,
              persistent:true,
              //emoji: "😆",
              title: self.$t('global_restore_dashboard'),
              //firstLineText: "실행중인 프로그램을 종료합니다.",
              secondLineText: self.$t('global_cleaning_up_screen'),
              // thirdLineText: "셋째줄",
              };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);
      //ipcRenderer.send("restore_virtual");
    },
    buttonClose: function () {
      window.close();
      /* Synchronous message emmiter and handler */
      //console.log(ipcRenderer.sendSync('synchronous-message', 'sync ping'))

      /* Blocked for 10 sec!!! as waiting for synchronous-message. */

      /* Async message sender */
      //ipcRenderer.send('asynchronous-message', 'async ping')

      //ipcRenderer.send("close");

      //alert();
      //          this.w.close();
    },
    isShowWindow: async function () {
      //ipcRenderer.send("minimize");
      //미니마이즈가 아니라 화면에서 숨겨주기만 한ㅏ. 0x0
          if(this.showWindow != window.is_show_window){
              //this.buttonRestore();

/*
              this.windowLoading=true;
            setTimeout(() => {

              this.windowLoading=false;
            }, 2000);
            */
/*
            let alertDialogInfo = {
              timeout: 2000,
              persistent:true,
              //emoji: "😆",
              title: "화면 준비 중...",
              //firstLineText: "실행중인 프로그램을 종료합니다.",
              secondLineText: "데이터를 정리 중입니다.",
              // thirdLineText: "셋째줄",
              };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);
*/

          }
        this.showWindow = window.is_show_window;



        //ipcRenderer.invoke('is_show_window');

        // console.log('show_window_return',this.showWindow );
    },
    async windowRefresh() {
      this.$buttonReload();
      this.isRefresh = true;

    },
    async clearCloseBot() {
      this.$clearCloseBot();

      

    },

    async globalTicker() {
      /*
      let systemOption = {
                            exchange : 'bybit', //거래소
                            symbol : 'BTC/USDT', //대상코인
                            api : //api키
                            {
                            },
                            currency : 'USDT', //기준재화
                            trademode : 'future', //선물모드
                            testmode : true, //테스트모드
                            candle : 3, //기준봉 1,3,5분봉
                            watch : 1, //감시주기 1~999999초
                          };
*/
      //let isExchangeSetup = this.$store.dispatch("UPDATE_SETTINGS", systemOption);
      //티커 가져오기
      //this.item = await this.$store.state.exchange.fetchBalance();
      //console.log("CCXT fetchBalance :", this.item);
      /*
      if(isExchangeSetup){
        console.log('isExchangeSetup',isExchangeSetup);
        console.log('systemOption',systemOption);
        this.$localData('systemOption',systemOption);
      }
*/
      /*
      const exchange = new ccxt.bybit({
        //enableRateLimit: true,
        options: {
          //  defaultType: 'future'
        },
      });
      //테스트서버
      exchange.setSandboxMode(true); // enable sandbox mode

      //티커 가져오기
      this.item = await exchange.fetchBalance();

      console.log("CCXT fetchBalance :", this.item);
*/
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "RixYeoljeongdo_Regular";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2102-01@1.0/RixYeoljeongdo_Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SBAggroB";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
    @import url('https://crypto25.org/assets/fonts/Geomanist-Regular-Webfont/stylesheet.css');


    body *{font-family: -apple-system,BlinkMacSystemFont,"Malgun Gothic","맑은 고딕",helvetica,"Apple SD Gothic Neo",sans-serif;}
    .v-btn {font-family: geomanist-regular-webfont;}
    h1,h2,h3 {font-family: geomanist-medium-webfont;}




html {
  overflow: scroll;
  overflow-x: hidden;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.CoinNumber {
  font-family: "SBAggroB";
}

.v-item-group.v-bottom-navigation .v-btn {
    border-radius: 0;
    box-shadow: none;
    flex: 0 1 auto;
    font-size: 0.75rem;
    max-width: 168px;
    min-width: 80px;
    position: relative;
    text-transform: none;
    height: auto;
    background-color: transparent;
}

.v-slide-group__next, .v-slide-group__prev {
    align-items: center;
    display: flex;
    flex: 0 1 52px;
    justify-content: center;
    min-width: 15px !important;
}

.v-progress-linear {z-index: 0 !important;}



.v-select__selection{font-size:14px !important;}
.v-text-field__prefix{font-size:14px !important;}
.v-text-field__suffix{font-size:14px !important;}
.v-label:not(.v-label--active) {font-size:14px !important;}
</style>