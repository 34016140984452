<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          
          {{$t('global_option_preset_edit')}}
        </v-btn>
      </template>
      <v-card v-if="preset_option">
        <v-card-title> {{$t('global_option_txt')}}: {{preset_id}}
          <v-spacer></v-spacer>
           <small>{{getBotInfo(bot_id).symbol}}</small></v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 100vh;">


          <v-list color="transparent" class="mt-3">
          <h4 class="text-left mb-2 pl-4 pb-2" style="font-size:16px;">{{$t('global_option_title_candle')}}({{preset_option.side}})</h4>
            <v-list-item>
              <v-select
                :label='$t("global_option_select_timeframe")'
                v-model="preset_option.catch.timeframe"
                :items="timeframe_type"
                required
                outlined
                dense
                class="mb-2"
                hide-details=""
              ></v-select>
            </v-list-item>


<!-- 이동평균선-->
            <v-divider class="mt-2 " ></v-divider>
          <h4 class="text-left mt-4 mb-4 pl-4 pb-2" style="font-size:16px;">
            {{$t("global_option_title_ma")}}
            ({{getBotInfo(bot_id).side}})</h4>
            <v-row no-gutters class="mt-2 mb-3">
              <v-col cols="4" sm="12" md="12">

                  <v-list-item>

                          <v-select
                            label="MA5"
                            v-model="preset_option.catch.ma5"
                            :items="ma_type"
                            required
                            outlined
                            dense
                            class="mb-2"
                            hide-details=""
                            :prefix='$t("global_option_select_ma_txt",{ma:5})'
                            :suffix='$t("global_option_select_catch")'
                          ></v-select>


                  </v-list-item>

                  
              </v-col>
              <v-col cols="4" sm="12" md="12">

                  <v-list-item>

                          <v-select
                            label="MA10"
                            v-model="preset_option.catch.ma10"
                            :items="ma_type"
                            required
                            outlined
                            dense
                            class="mb-2"
                            hide-details=""
                            :prefix='$t("global_option_select_ma_txt",{ma:10})'
                            :suffix='$t("global_option_select_catch")'
                          ></v-select>


                  </v-list-item>

                  
              </v-col>
              <v-col cols="4" sm="12" md="12">

                  <v-list-item>

                          <v-select
                            label="MA20"
                            v-model="preset_option.catch.ma20"
                            :items="ma_type"
                            required
                            outlined
                            dense
                            class="mb-2"
                            hide-details=""
                            :prefix='$t("global_option_select_ma_txt",{ma:20})'
                            :suffix='$t("global_option_select_catch")'
                          ></v-select>


                  </v-list-item>

                  
              </v-col>
              <v-col cols="4" sm="12" md="12">

                  <v-list-item>

                          <v-select
                            label="MA60"
                            v-model="preset_option.catch.ma60"
                            :items="ma_type"
                            required
                            outlined
                            dense
                            class="mb-2"
                            hide-details=""
                            :prefix='$t("global_option_select_ma_txt",{ma:60})'
                            :suffix='$t("global_option_select_catch")'
                          ></v-select>


                  </v-list-item>

                  
              </v-col>
              <v-col cols="4" sm="12" md="12">

                  <v-list-item>

                          <v-select
                            label="MA120"
                            v-model="preset_option.catch.ma120"
                            :items="ma_type"
                            required
                            outlined
                            dense
                            class="mb-2"
                            hide-details=""
                            :prefix='$t("global_option_select_ma_txt",{ma:120})'
                            :suffix='$t("global_option_select_catch")'
                          ></v-select>


                  </v-list-item>

                  
              </v-col>

<!-- 위에 설정만으로도 사용 가능하여 이부분은보류-->
              <v-col cols="12" v-if="false">

                  <v-list-item>

                          <v-select
                            label="이동평균선 규칙"
                            v-model="preset_option.catch.macompare"
                            :items="macompare_type"
                            required
                            outlined
                            dense
                            class="mb-2"
                            hide-details=""
                          ></v-select>


                  </v-list-item>

                  
              </v-col>

<!-- 위에 설정만으로도 사용 가능하여 이부분은보류-->
            </v-row>

<!-- 이동평균선-->



<!-- 상대강도지수(RSI)-->

            <v-divider class="mt-2 " ></v-divider>
<h4 class="text-left mt-2 pl-4 pb-2" style="font-size:16px;"><!--상대강도지수-->
            {{$t("global_option_title_rsi")}}
            ({{getBotInfo(bot_id).side}})</h4>

<div >

            <v-row no-gutters class="mt-2">
              <v-col cols="6">

                  <v-list-item>


              <v-text-field 
              v-model="preset_option.catch.rsi1_length"
              @change="absMinMax"
                :label="$t('global_option_rsi_length')"
                :placeholder="$t('global_option_rsi_length')"
                outlined
                dense
                type="number"
                suffix=""
                prefix="RSI 1"
                ref="name"
                
                class="mb-2"
                hide-details=""
              ></v-text-field>


                  </v-list-item>

                  
              </v-col>
              <v-col cols="3">

                  <v-list-item>


              <v-text-field 
              v-model="preset_option.catch.rsi1_min"
              @change="absMinMax"
                :label="$t('global_option_min')"
                :placeholder="$t('global_option_min_txt')"
                outlined
                dense
                type="number"
                suffix=""
                ref="name"
                
                class="mb-2"
                hide-details=""
              ></v-text-field>


                  </v-list-item>

                  
              </v-col>
              <v-col cols="3">
                  <v-list-item>

                    <v-text-field
              v-model="preset_option.catch.rsi1_max"
              @change="absMinMax"
                :label="$t('global_option_max')"
                :placeholder="$t('global_option_max_txt')"
                      outlined
                      dense
                      type="number"
                      suffix=""
                      ref="name"
                      
                      class="mb-2"
                      hide-details=""
                    ></v-text-field>
                  </v-list-item>

                  
              </v-col>
            </v-row>
            <v-row no-gutters class=" mb-3">
              <v-col cols="6">

                  <v-list-item>


              <v-text-field 
              v-model="preset_option.catch.rsi2_length"
              @change="absMinMax"
                :label="$t('global_option_rsi_length')"
                :placeholder="$t('global_option_rsi_length')"
                outlined
                dense
                type="number"
                suffix=""
                prefix="RSI 2"
                ref="name"
                
                class="mb-2"
                hide-details=""
              ></v-text-field>


                  </v-list-item>

                  
              </v-col>
              <v-col cols="3">

                  <v-list-item>


              <v-text-field 
              v-model="preset_option.catch.rsi2_min"
              @change="absMinMax"
                :label="$t('global_option_min')"
                :placeholder="$t('global_option_min_txt')"
                outlined
                dense
                type="number"
                suffix=""
                ref="name"
                
                class="mb-2"
                hide-details=""
              ></v-text-field>


                  </v-list-item>

                  
              </v-col>
              <v-col cols="3">
                  <v-list-item>

                    <v-text-field
              v-model="preset_option.catch.rsi2_max"
              @change="absMinMax"
                :label="$t('global_option_max')"
                :placeholder="$t('global_option_max_txt')"
                      outlined
                      dense
                      type="number"
                      suffix=""
                      ref="name"
                      
                      class="mb-2"
                      hide-details=""
                    ></v-text-field>
                  </v-list-item>

                  
              </v-col>
            </v-row>


</div>

<!-- 상대강도지수(RSI)-->








            <v-divider class="mt-2 " ></v-divider>

<h4 class="text-left mt-2 pl-4 pb-2" style="font-size:16px;"><!--패턴분석-->
          {{$t('global_option_title_pattern')}}
          ({{getBotInfo(bot_id).side}})</h4>

            <v-list-item class="mt-2 mb-2">
              <v-select
                :label='$t("global_option_pattern_length")'
                v-model="preset_option.catch.pattern_length"
                :items="pattern_length_type"
                required
                outlined
                dense
                class="mb-2"
                hide-details=""
              >
                        <template v-slot:append>        
                          <v-icon style="position: absolute;right:-5px;"> mdi-menu-down </v-icon> 
                        </template></v-select>
            </v-list-item>
            <v-list-item class="mt-2" v-if="preset_option.catch.pattern_length > 0">
            <v-row no-gutters class="mt-2 mb-2">
                <v-col cols="" style="max-width:20%;padding-right:1px;">
                  <v-select
                  :disabled="preset_option.catch.pattern_length < 5"
                  :style="preset_option.catch.pattern_length < 5?'opacity:0.3':''"
                    :label='$t("global_option_select_pattern_txt",{candle:-5})'
                    :items="pattern_type_v2"
                    v-model="preset_option.catch.pattern_5"
                    required
                    outlined
                    dense
                    class="mb-2"
                    hide-details=""
                  >
                  
                        <template v-slot:append>        
                          <v-icon style="position: absolute;right:-5px;"> mdi-menu-down </v-icon> 
                        </template></v-select>
                </v-col>
                <v-col cols="" style="max-width:20%;padding-right:1px;padding-left:1px;">
                  <v-select
                  :disabled="preset_option.catch.pattern_length < 4"
                  :style="preset_option.catch.pattern_length < 4?'opacity:0.3':''"
                    :label='$t("global_option_select_pattern_txt",{candle:-4})'
                    :items="pattern_type_v2"
                    v-model="preset_option.catch.pattern_4"
                    required
                    outlined
                    dense
                    class="mb-2"
                    hide-details=""
                  >
                  
                        <template v-slot:append>        
                          <v-icon style="position: absolute;right:-5px;"> mdi-menu-down </v-icon> 
                        </template></v-select>
                </v-col>
                <v-col cols="" style="max-width:20%;padding-right:1px;padding-left:1px;">
                  <v-select
                  :disabled="preset_option.catch.pattern_length < 3"
                  :style="preset_option.catch.pattern_length < 3?'opacity:0.3':''"
                    :label='$t("global_option_select_pattern_txt",{candle:-3})'
                    :items="pattern_type_v2"
                    v-model="preset_option.catch.pattern_3"
                    required
                    outlined
                    dense
                    class="mb-2"
                    hide-details=""
                  >
                  
                        <template v-slot:append>        
                          <v-icon style="position: absolute;right:-5px;"> mdi-menu-down </v-icon> 
                        </template></v-select>
                </v-col>
                <v-col cols="" style="max-width:20%;padding-right:1px;padding-left:1px;">
                  <v-select
                  :disabled="preset_option.catch.pattern_length < 2"
                  :style="preset_option.catch.pattern_length < 2?'opacity:0.3':''"
                    :label='$t("global_option_select_pattern_txt",{candle:-2})'
                    :items="pattern_type_v2"
                    v-model="preset_option.catch.pattern_2"
                    required
                    outlined
                    dense
                    class="mb-2"
                    hide-details=""
                  >
                  
                        <template v-slot:append>        
                          <v-icon style="position: absolute;right:-5px;"> mdi-menu-down </v-icon> 
                        </template></v-select>
                </v-col>
                <v-col cols="" style="max-width:20%;padding-left:1px;">
                  <v-select
                  :disabled="preset_option.catch.pattern_length < 1"
                  :style="preset_option.catch.pattern_length < 1?'opacity:0.3':''"
                    :label='$t("global_option_select_pattern_txt",{candle:-1})'
                    :items="pattern_type_v2"
                    v-model="preset_option.catch.pattern_1"
                    required
                    outlined
                    dense
                    class="mb-2"
                    hide-details=""
                  >
                  
                        <template v-slot:append>        
                          <v-icon style="position: absolute;right:-5px;"> mdi-menu-down </v-icon> 
                        </template></v-select>
                </v-col>

            </v-row>
            </v-list-item>

            <v-list-item class="mt-2" v-if="false">
              <v-select
                label="감시할 패턴 선택"
                v-model="preset_option.catch.pattern"
                :items="pattern_type"
                required
                outlined
                dense
                class="mb-2"
                hide-details=""
              ></v-select>
            </v-list-item>


<!-- 패턴범위 변동폭 -->
<div >

            <v-row no-gutters class="mt-2 mb-3"
              v-if="preset_option.catch.pattern_length > 1">
              <v-col>

                  <v-list-item>


              <v-text-field 
              v-model="preset_option.catch.candle_min"
              @change="absMinMax"
                :label='$t("global_option_pattern_range_min")'
                :placeholder='$t("global_option_min_txt")'
                outlined
                dense
                type="number"
                suffix="%"
                ref="name"
                
                class="mb-2"
                hide-details=""
              ></v-text-field>


                  </v-list-item>

                  
              </v-col>
              <v-col>
                  <v-list-item>

                    <v-text-field
              v-model="preset_option.catch.candle_max"
              @change="absMinMax"
                :label='$t("global_option_pattern_range_max")'
                :placeholder='$t("global_option_max_txt")'
                      outlined
                      dense
                      type="number"
                      suffix="%"
                      ref="name"
                      
                      class="mb-2"
                      hide-details=""
                    ></v-text-field>
                  </v-list-item>

                  
              </v-col>
            </v-row>



            <v-row no-gutters class="mt-2 mb-3"
              v-if="preset_option.catch.pattern_length > 0">
              <v-col>

                  <v-list-item>


              <v-text-field
              v-model="preset_option.catch.candle_last_min"
              @change="absMinMax"
                :label='$t("global_option_pattern_prev_min")'
                :placeholder='$t("global_option_min_txt")'
                outlined
                dense
                type="number"
                suffix="%"
                ref="name"
                
                class="mb-2"
                hide-details=""
              ></v-text-field>


                  </v-list-item>

                  
              </v-col>
              <v-col>
                  <v-list-item>

                    <v-text-field
              v-model="preset_option.catch.candle_last_max"
              @change="absMinMax"
                :label='$t("global_option_pattern_prev_max")'
                :placeholder='$t("global_option_max_txt")'
                      outlined
                      dense
                      type="number"
                      suffix="%"
                      ref="name"
                      
                      class="mb-2"
                      hide-details=""
                    ></v-text-field>
                  </v-list-item>

                  
              </v-col>
            </v-row>

</div>
<!-- 패턴범위 변동폭 -->



            <v-divider class="mt-2 " ></v-divider>

<h4 class="text-left mt-2 pl-4 pb-2" style="font-size:16px;">
            <!--진입수량-->{{$t('global_option_amount')}}
            ({{getBotInfo(bot_id).side}})</h4>
            <v-list-item class="mt-2" v-if="globalTicker && position">
              <v-text-field
                :label="position ? $t('global_option_bot_size')+' ≈ $' + $addComma($setFloat(preset_amount*globalTicker.close/position.data.leverage,2))  + ` (${$t('global_option_leverage')} x${position.data.leverage})`: $t('global_option_bot_size')+' ≈ $' + $addComma($setFloat(preset_amount*globalTicker.close,2))"
                :placeholder="$t('global_option_bot_size')"
                outlined
                dense
                type="number"
                :suffix="$store.state.systemOption.symbol"
                ref="name"
                
                class="mb-2"
                hide-details=""
                v-model="preset_amount"
                @change="amountMinMax"
              ></v-text-field>
            </v-list-item>




            <v-list-item class="mt-2" v-else>
              <v-text-field
                :label="$t('global_option_bot_size')"
                :placeholder="$t('global_option_bot_size')"
                outlined
                dense
                type="number"
                :suffix="$store.state.systemOption.symbol"
                ref="name"
                
                class="mb-2"
                hide-details=""
                v-model="preset_option.amount"
                @change="amountMinMax"
              ></v-text-field>
            </v-list-item>

               <div style="text-align:center;padding-top:0;">
                  <small style="color:red">
                    {{$t("global_option_title_position_amount_description")}}
                  </small>
               </div>

              
            <v-row no-gutters class="mt-3">
              <v-col>

                  <v-list-item>


              <v-text-field
              v-model="preset_option.limit.time"
                :label='$t("global_option_order_delay")'
                :placeholder='$t("global_option_order_delay_txt")'
                outlined
                dense
                type="number"
                :suffix='$t("global_option_minutes")'
                ref="name"
                
                class="mb-2"
                @change="delayMinMax"
                hide-details=""
              ></v-text-field>


                  </v-list-item>

                  
              </v-col>
              <v-col>
                  <v-list-item>

                    <v-text-field
                    v-model="preset_option.limit.total"
                      :label='$t("global_option_bot_count_max")'
                      :placeholder='$t("global_option_bot_count_max_txt")'
                      outlined
                      dense
                      type="number"
                      :suffix='$t("global_option_times")'
                      ref="name"
                      
                      class="mb-2"
                      hide-details=""
                    ></v-text-field>
                  </v-list-item>

                  
              </v-col>
            </v-row>



            <v-list-item class="mt-2" v-if="globalTicker && position">


                      <v-select
                        :label='$t("global_option_order_metic_type")'
                        v-model="preset_option.amounttype"
                        :items="amount_type"
                        required
                        outlined
                        dense
                        class="mb-"
                        hide-details=""
                      ></v-select>


            </v-list-item>

<!-- 비중조절 -->

<div>
            <v-list-item class="mt-2">


                      <v-select
                        :label='$t("global_option_position_balance")'
                        v-model="preset_option.synctype"
                        :items="sync_type"
                        required
                        outlined
                        dense
                        class="mb-"
                        hide-details=""
                      ></v-select>


            </v-list-item>
</div>
<!-- 비중조절 -->



            <div v-if="$store.state.balance &&position && preset_option.amounttype !='normal' && !preset_option.synctype" >
              <div style="text-align:center;padding-top:5px;" v-if="preset_option.amounttype=='normal'">
                  <small style="opacity:0.5">{{$t('global_option_next_size')}}: {{ $setFloat(preset_option.amount,2,true) }}

 / {{$t('global_option_next_value')}} ${{calcAmount('normal')}}

    <strong v-if="$store.state.balance.USDT.free > preset_option.amount" style="font-size:75%;">({{$t('global_option_next_possible')}})</strong>
    <strong v-else style="font-size:75%;color:red;">({{$t('global_option_next_possible')}})</strong>
<span v-if="$store.state.balance">
 / {{$t('global_option_next_position_available')}} ${{$setFloat($store.state.balance.USDT.free,2,true)}}
</span>
                  </small>
              </div>
              <div style="text-align:center;padding-top:5px;" v-if="preset_option.amounttype=='amount_x_bot'">
                  <small style="opacity:0.5">{{$t('global_option_next_size')}}: {{$setFloat(amount_x_bot(),2,true) }}

 / {{$t('global_option_next_value')}} ${{calcAmount('amount_x_bot')}}
    <strong v-if="$store.state.balance.USDT.free > calcAmount('amount_x_bot')" style="font-size:75%;">({{$t('global_option_next_possible')}})</strong>
    <strong v-else style="font-size:75%;color:red;">({{$t('global_option_next_possible')}})</strong>
<span v-if="$store.state.balance">
 / {{$t('global_option_next_position_available')}} ${{$setFloat($store.state.balance.USDT.free,2,true)}}
</span>

                  </small>

              </div>
              <div style="text-align:center;padding-top:5px;" v-if="position && preset_option.amounttype=='position_x_bot'">
                  <small style="opacity:0.5">{{$t('global_option_next_size')}}: {{ $setFloat(position_x_bot(),2,true) }}
 / {{$t('global_option_next_value')}} ${{calcAmount('position_x_bot')}}

    <strong v-if="$store.state.balance.USDT.free > calcAmount('position_x_bot')" style="font-size:75%;">({{$t('global_option_next_possible')}})</strong>
    <strong v-else style="font-size:75%;color:red;">({{$t('global_option_next_possible')}})</strong>
<span v-if="$store.state.balance">
 / {{$t('global_option_next_position_available')}} ${{$setFloat($store.state.balance.USDT.free,2,true)}}
</span>

                  </small>
              </div>
              </div>



            <v-divider class="mt-2 " />
<h4 class="text-left mt-2 pl-4 pb-2" style="font-size:16px;">{{$t('global_option_prevention_add_bot')}}({{preset_option.side}})</h4>
              
            <v-row no-gutters class="mt-1 mb-1">
              <v-col>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="text-align: left">
                        {{$t('global_option_prevention_burn')}}
                      </v-list-item-title>
                      <v-list-item-subtitle><small>{{$t('global_position_text')}}({{preset_option.side}}) 
                        {{$t('global_option_prevention_average_base')}}</small></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch inset v-model="preset_option.active.burn"></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  
              </v-col>
              <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="text-align: left">
                        {{$t('global_option_prevention_water')}}
                      </v-list-item-title>
                      <v-list-item-subtitle><small>{{$t('global_position_text')}}({{preset_option.side}}) {{$t('global_option_prevention_average_base')}}</small></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch inset v-model="preset_option.active.water"></v-switch>
                    </v-list-item-action>
                  </v-list-item>

                  
              </v-col>
              <v-col class="mt-1" cols="12" v-if="preset_option.active.burn">

                    <v-list-item class="">
                      <v-select
                        :label='$t("global_option_prevention_more_options")'
                        v-model="preset_option.catch.entrybase"
                        :items="entrybase_type"
                        required
                        outlined
                        dense
                        class="mb-2"
                        hide-details=""
                      ></v-select>
                    </v-list-item>

              </v-col>
            </v-row>



            <v-divider class="" />

            <h4 class="text-left mt-2 pl-4 pb-2" style="font-size:16px;">
            {{$t('global_option_title_bot_close')}}
            ({{preset_option.side}})</h4>


            <v-row no-gutters class="mt-0 mb-1">
              <v-col>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="text-align: left">
                        {{$t('global_option_title_bot_profit')}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch inset v-model="preset_option.active.profit"></v-switch>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item>
                    <v-text-field
                    v-model="preset_option.profit"
                      :label='$t("global_option_profit_txt")'
                      :placeholder='$t("global_option_profit_txt")'
                      outlined
                      dense
                      type="number"
                      suffix="%"
                      ref="name"
                      
                      class="mb-2"
                      hide-details=""
                    ></v-text-field>
                  </v-list-item>
                  
              </v-col>
              <v-col>

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="text-align: left">
                        {{$t('global_option_title_bot_stoploss')}}
            ({{preset_option.side}})
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch inset v-model="preset_option.active.stoploss"></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-text-field
                  v-model="preset_option.stoploss"
                      :label='$t("global_option_stoploss_txt")'
                      :placeholder='$t("global_option_stoploss_txt")'
                    outlined
                    dense
                    type="number"
                    suffix="%"
                    ref="name"
                    
                    class="mb-2"
                    hide-details=""
                  ></v-text-field>
                </v-list-item>
                
              </v-col>
              <v-col cols="12"
                      v-if="preset_option.active.profit ">

                    <v-list-item class="mt-2">
                      <v-select
                        :label='$t("global_option_profit_traililing")'
                        v-model="preset_option.catch.ordertype"
                        :items="order_type"
                        required
                        outlined
                        dense
                        class="mb-2"
                        hide-details=""
                      ></v-select>
                    </v-list-item>

              </v-col>
            </v-row>


            
            <v-divider class="" />

            

            <v-row no-gutters class="mt-0 mb-1">

              
              <v-col cols="12">

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title style="text-align: left">
                        
                      <h4>{{$t('global_option_title_position_exit')}}
                       ({{preset_option.side}})</h4>
                        
                        
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch inset v-model="preset_option.active.profit_position"></v-switch>
                    </v-list-item-action>
                  </v-list-item>

                  
              </v-col>

              <v-col cols="6" class="mb-2">
                  <v-list-item >
                    <v-text-field 
                    v-model="preset_option.profit_position"
                      :label='$t("global_option_profit_txt")'
                      :placeholder='$t("global_option_profit_txt")'
                      outlined
                      dense
                      type="number"
                      suffix="%"
                      ref="name"
                      
                      class="mb-2 "
                      hide-details=""
                    ></v-text-field>
                  </v-list-item>
                
              </v-col>

              <v-col cols="6" class="mb-2">
                  <v-list-item >
                            <v-text-field
                            v-model="preset_option.profit_time"
                              :label='$t("global_option_clear_delay")'
                              :placeholder='$t("global_option_profit_txt")'
                              outlined
                              dense
                              type="number"
                      :suffix='$t("global_option_minutes")'
                              ref="name"
                              
                              class="mb-2"
                              hide-details=""
                            ></v-text-field>
                  </v-list-item>
                
              </v-col>

              <v-col cols="6">
                  <v-list-item>
                      <v-select
                        :label='$t("global_option_clear_type")'   
                        v-model="preset_option.profittype"
                        :items="profit_type"
                        required
                        outlined
                        dense
                        class="mb-2"
                        hide-details=""
                      >
                        <template v-slot:append>        
                          <v-icon style="position: absolute;right:-5px;"> mdi-menu-down </v-icon> 
                        </template></v-select>
                  </v-list-item>
              </v-col>
              <v-col cols="6">

                  <v-list-item >
                    <v-text-field
                    v-model="preset_option.profit_amount"
                      type="number"
                      :label="preset_option.profittype =='percent' ? $t('global_option_position_amount_pecent') : $t('global_option_position_amount_manual')"
                      :suffix="preset_option.profittype =='percent' ? '%' :$store.state.systemOption.symbol"
                      :placeholder="preset_option.profittype =='percent' ? '%' : ''"
                      outlined
                      dense
                      ref="name"
                      
                      class="mb-2"
                      hide-details=""
                    ></v-text-field>
                  </v-list-item>
              </v-col>


              <v-col cols="12"
                      v-if="preset_option.active.profit_position ">

                    <v-list-item class="mt-2">
                      <v-select
                        :label='$t("global_option_profit_traililing")'
                        v-model="preset_option.catch.ordertype_profit"
                        :items="order_type_profit"
                        required
                        outlined
                        dense
                        class="mb-2"
                        hide-details=""
                      >
                        <template v-slot:append>        
                          <v-icon style="position: absolute;right:-5px;"> mdi-menu-down </v-icon> 
                        </template></v-select>
                    </v-list-item>

              </v-col>

                  
            </v-row>

            <v-divider class="mt-3" />
            
            <v-row no-gutters class="mt- mb-1">
              <v-col cols="12">

                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title style="text-align: left">
                      <h4>{{$t('global_option_title_position_stoploss')}}
                       ({{preset_option.side}})</h4>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch inset v-model="preset_option.active.stoploss_position"></v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item>
                  <v-text-field
                  v-model="preset_option.stoploss_position"
                    :label='$t("global_option_stoploss_txt")'
                    :placeholder='$t("global_option_stoploss_txt")'
                    outlined
                    dense
                    type="number"
                    suffix="%"
                    ref="name"
                    
                    class="mb-2"
                    hide-details=""
                  ></v-text-field>
                </v-list-item>
                
              </v-col>
              <v-col cols="12" style="text-align:center;padding-bottom:0px;">
                  <small style="opacity:0.5">
{{$t('global_option_title_position_description')}}</small>
              </v-col>
            </v-row>





            



            <v-divider class=""  v-if="false"/>

            <v-row no-gutters class="mt-3" v-if="false">

              <v-col cols="12">

                    <v-list-item class="mt-2">
                      <v-select
                        label="펀딩피 지급시"
                        v-model="preset_option.catch.fundingtype"
                        :items="funding_type"
                        required
                        outlined
                        dense
                        class="mb-2"
                        hide-details=""
                      ></v-select>
                    </v-list-item>

              </v-col>

            </v-row>

            <v-divider class="mt-2 "  v-if="false"/>

          <h4 class="text-center mt-4" style="color:#888;" v-if="false" >포지션({{preset_option.side}}) 청산관리</h4>


            <v-row no-gutters class="mt-0 mb-1" v-if="false">
              <v-col cols="12">


                    <v-list-item class="mt-2">
                      <v-select
                        label="청산관리 방법"
                        v-model="preset_option.catch.liquidationtype"
                        :items="liquidation_type"
                        required
                        outlined
                        dense
                        class="mb-2"
                        hide-details=""
                      ></v-select>
                    </v-list-item>

                  
              </v-col>
              <v-col>


              </v-col>
              <v-col cols="12" style="text-align:center;padding:22px;padding-top:0; padding-bottom:5px;">
                  <small style="opacity:0.5;font-size:10px;line-height:10px;;"><strong style="color:red;">고배율(레버리지) 운용시 강제청산의 위험이 있습니다.</strong> 특히, 포지션 수량, 거래금액, 거래소와의 네트워크 연결 등 다양한 요소들에 의해 방해받을 수 있음을 참고하여 운용해주세요. </small>
              </v-col>
            </v-row>





          </v-list>





        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
{{$t('global_option_cancel')}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="savePresetOption"
          >
{{$t('global_option_save_preset')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>


export default {
  name: "botoption",
  title: "botoption",
  props: {
    //dialog: {  required: false, default: false },
    bot_id: { type: String, required: false, default: "" },
    preset_id : { type: String, required: false, default: "" },
    side : { type: String, required: false, default: "" },
    preset_no : { type: Number, required: false, default: -1 },
  },
  components: {},
  data() {
    return {

      dialog: false,

        result: [],
        delay: 700,
        clicks: 0,
        timer: null,

      //조건실행
      transition_type: [
        // { text: "사용안함", value: '' },
        // { text: "프리셋 전략실행", value: 'preset' },
        // { text: "전략끄기", value: 'off' },
        { text: this.$t("global_option_transition_type_none"), value: '' },
        { text: this.$t("global_option_transition_type_preset"), value: 'preset' },
        { text: this.$t("global_option_transition_type_off"), value: 'off' },
        //{ text: "전략켜기", value: 'on' },
      ],
      transition_base_type: [
        // { text: "열린 봇 수", value: 'active_bot' },
        // { text: "포지션 손익 (%)", value: 'position_profit' },
        // { text: "포지션 투입 ($)", value: 'position_value' },
        // { text: "총 투입 자산 ($)", value: 'balance_used' },
        { text: this.$t("transition_base_type_active_bot"), value: 'active_bot' },
        { text: this.$t("transition_base_type_position_profit"), value: 'position_profit' },
        { text: this.$t("transition_base_type_position_value"), value: 'position_value' },
        { text: this.$t("transition_base_type_balance_used"), value: 'balance_used' },
        //{ text: "총 투입 비중(%)", value: 'balance_ratio' },
        //{ text: "포지션 비중(%)", value: 'position_ratio' },
      ],
      transition_condition_type: [
        // { text: "이상", value: 'up' },
        // { text: "이하", value: 'down' },
        { text: this.$t("transition_base_type_up"), value: 'up' },
        { text: this.$t("transition_base_type_down"), value: 'down' },
      ],


        profit_type: [
          // { text: "퍼센트 지정", value: 'percent' },
          // { text: "수량 입력", value: 'amount' },
          { text: this.$t("global_option_profit_type_percent"), value: 'percent' },
          { text: this.$t("global_option_profit_type_amount"), value: 'amount' },
        ],




      ma_type: [
        { text: this.$t("global_option_select_up"), value: 'up' },
        { text: this.$t("global_option_select_down"), value: 'down' },
        { text: this.$t("global_option_select_any"), value: '' },
      ],


      macompare_type: [
        { text: "배열규칙 무시", value: '' },

        { text: "정배열 : MA20>MA10>MA5>현재가", value: 'ma20>ma10>ma5>close' },
        { text: "정배열 : MA10>MA5>현재가", value: 'ma10>ma5>close' },

        { text: "역배열 : 현재가<MA5<MA10<MA20", value: 'close<ma5<ma10<ma20' },
        { text: "역배열 : 현재가<MA5<MA10", value: 'close<ma5<ma10' },
      ],



      timeframe_type: [
        { text: "1m", value: '1m' },
        { text: "3m", value: '3m' },
        { text: "5m", value: '5m' },
        { text: "15m", value: '15m' },
        { text: "30m", value: '30m' },

        { text: "1h", value: '1h' },
        //{ text: "2시간", value: '2h' }, // bitget에서는 지원x
        { text: "4h", value: '4h' },
        //{ text: "6시간", value: '6h' }, // bitget에서는 지원x
        { text: "12h", value: '12h' },
        { text: "1d", value: '1d' },
      ],

      pattern_length_type: [
        // { text: "직전 5개 패턴 감시", value: 5 },
        // { text: "직전 4개 패턴 감시", value: 4 },
        // { text: "직전 3개 패턴 감시", value: 3 },
        // { text: "직전 2개 패턴 감시 (주의😐)", value: 2 },
        // { text: "직전 1개 패턴 감시 (위험😫)", value: 1 },
        // { text: "패턴 무시 (매우위험😱)", value: 0 },
        { text: this.$t("global_option_pattern_length_type_txt",{value:5,desc:""}), value: 5 },
        { text: this.$t("global_option_pattern_length_type_txt",{value:4,desc:""}), value: 4 },
        { text: this.$t("global_option_pattern_length_type_txt",{value:3,desc:""}), value: 3 },
        { text: this.$t("global_option_pattern_length_type_txt",{value:2,desc:"(주의😐)"}), value: 2 },
        { text: this.$t("global_option_pattern_length_type_txt",{value:1,desc:"(위험😫)"}), value: 1 },
        { text: this.$t("global_option_pattern_length_type_any",{value:'',desc:"(매우위험😱)"}), value: 0 },

      ],
      
      pattern_type_v2: [
        // { text: "상승", value: '+' },
        // { text: "하락", value: '-' },
        // { text: "보합", value: '=' },
        // { text: "무시", value: '*' },
        { text: this.$t("global_option_pattern_type_v2_plus"), value: '+' },
        { text: this.$t("global_option_pattern_type_v2_minus"), value: '-' },
        { text: this.$t("global_option_pattern_type_v2_eq"), value: '=' },
        { text: this.$t("global_option_pattern_type_v2_any"), value: '*' },
      ],

      pattern_type: [
        { text: "패턴 무시 (매우위험)", value: '*' },

        { text: "[　　　　▲] 직전 상승(위험)", value: '+' },
        { text: "[　　　▲▲] 2연속 상승(주의)", value: '++' },
        { text: "[　　▲▲▽] 2연속 상승 > 1하락 ", value: '++-' },
        { text: "[　　▲▲▲] 3연속 상승", value: '+++' },
        { text: "[　▲▲▲▽] 3연속 상승 > 1하락 ", value: '+++-' },
        { text: "[　▲▲▲▲] 4연속 상승", value: '++++' },
        { text: "[▲▲▲▲▽] 4연속 상승 > 1하락 ", value: '++++-' },
        { text: "[▲▲▲▲▲] 5연속 상승", value: '+++++' },
        { text: "[▲▲▲▽▲] 3연속 상승 > 1하락 > 1상승 ", value: '+++-+' },
        { text: "[▲▲▲▽▽] 3연속 상승 > 2하락", value: '+++--' },

        { text: "[　　　　▽] 직전 하락(위험)", value: '-' },
        { text: "[　　　▽▽] 2연속 하락(주의)", value: '--' },
        { text: "[　　▽▽▲] 2연속 하락 > 1상승", value: '--+' },
        { text: "[　　▽▽▽] 3연속 하락", value: '---' },
        { text: "[　▽▽▽▲] 3연속 하락 > 1상승", value: '---+' },
        { text: "[　▽▽▽▽] 4연속 하락", value: '----' },
        { text: "[▽▽▽▽▲] 4연속 하락 > 1상승", value: '----+' },
        { text: "[▽▽▽▽▽] 5연속 하락", value: '-----' },
        { text: "[▽▽▽▲▽] 3연속 하락 > 1상승 > 1하락", value: '---+-' },
        { text: "[▽▽▽▲▲] 3연속 하락 > 2상승", value: '---++' },
      ],

      

      entrybase_type: [
        // { text: "현재 포지션이 손실 중에만, 추가매매 ", value: 'exchange' },
        // { text: "현재 포지션이 -1% 초과 손실 중에만, 추가매매", value: 'exchange_1' },
        // { text: "현재 포지션이 -2% 초과 손실 중에만, 추가매매", value: 'exchange_2' },
        // { text: "현재 포지션이 -3% 초과 손실 중에만, 추가매매", value: 'exchange_3' },
        // { text: "현재 포지션이 -4% 초과 손실 중에만, 추가매매", value: 'exchange_4' },
        // { text: "현재 포지션이 -5% 초과 손실 중에만, 추가매매", value: 'exchange_5' },
        // { text: "현재 포지션이 -6% 초과 손실 중에만, 추가매매", value: 'exchange_6' },
        // { text: "현재 포지션이 -7% 초과 손실 중에만, 추가매매", value: 'exchange_7' },
        // { text: "현재 포지션이 -8% 초과 손실 중에만, 추가매매", value: 'exchange_8' },
        // { text: "현재 포지션이 -9% 초과 손실 중에만, 추가매매", value: 'exchange_9' },
        // { text: "현재 포지션이 -10% 초과 손실 중에만, 추가매매", value: 'exchange_10' },
        // { text: "현재 포지션이 -15% 초과 손실 중에만, 추가매매", value: 'exchange_15' },
        // { text: "현재 포지션이 -20% 초과 손실 중에만, 추가매매", value: 'exchange_20' },
        // { text: "현재 포지션이 -25% 초과 손실 중에만, 추가매매", value: 'exchange_25' },
        // { text: "현재 포지션이 -30% 초과 손실 중에만, 추가매매", value: 'exchange_30' },
        // { text: "현재 포지션이 -35% 초과 손실 중에만, 추가매매", value: 'exchange_35' },
        // { text: "현재 포지션이 -40% 초과 손실 중에만, 추가매매", value: 'exchange_40' },
        // { text: "현재 포지션이 -45% 초과 손실 중에만, 추가매매", value: 'exchange_45' },
        // { text: "현재 포지션이 -50% 초과 손실 중에만, 추가매매", value: 'exchange_50' },
        // { text: "현재 포지션이 -60% 초과 손실 중에만, 추가매매", value: 'exchange_60' },
        // { text: "현재 포지션이 -70% 초과 손실 중에만, 추가매매", value: 'exchange_70' },
        // { text: "현재 포지션이 -80% 초과 손실 중에만, 추가매매", value: 'exchange_80' },
        // { text: "현재 포지션이 -90% 초과 손실 중에만, 추가매매", value: 'exchange_90' },
        { text: this.$t("global_option_entrybase_type_loss"), value: 'exchange' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-1%'}), value: 'exchange_1'},
        { text: this.$t("global_option_entrybase_type_txt",{value:'-2%'}), value: 'exchange_2' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-3%'}), value: 'exchange_3' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-4%'}), value: 'exchange_4' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-5%'}), value: 'exchange_5' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-6%'}), value: 'exchange_6' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-7%'}), value: 'exchange_7' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-8%'}), value: 'exchange_8' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-9%'}), value: 'exchange_9' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-10%'}), value: 'exchange_10' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-15%'}), value: 'exchange_15' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-20%'}), value: 'exchange_20' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-25%'}), value: 'exchange_25' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-30%'}), value: 'exchange_30' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-35%'}), value: 'exchange_35' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-40%'}), value: 'exchange_40' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-45%'}), value: 'exchange_45' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-50%'}), value: 'exchange_50' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-60%'}), value: 'exchange_60' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-70%'}), value: 'exchange_70' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-80%'}), value: 'exchange_80' },
        { text: this.$t("global_option_entrybase_type_txt",{value:'-90%'}), value: 'exchange_90' },
        /*
        봇마다 금액이 다른 경우, 가중치 없이 사용하는 것은 굉장히 위험할 수 있음,
        평균이 아닌 마지막 활성봇을 기준으로 하여 보완
        챗방 홧팅님 의견
        */
        // { text: "마지막 활성봇 손실 중에만 추가매매", value: 'bot' },
        // { text: "마지막 활성봇 -0.05% 초과 손실 중에만,추가매매", value: 'bot_0.05' },
        // { text: "마지막 활성봇 -0.06% 초과 손실 중에만,추가매매", value: 'bot_0.06' },
        // { text: "마지막 활성봇 -0.07% 초과 손실 중에만,추가매매", value: 'bot_0.07' },
        // { text: "마지막 활성봇 -0.08% 초과 손실 중에만,추가매매", value: 'bot_0.08' },
        // { text: "마지막 활성봇 -0.09% 초과 손실 중에만,추가매매", value: 'bot_0.09' },
        // { text: "마지막 활성봇 -0.1% 초과 손실 중에만,추가매매", value: 'bot_0.1' },
        // { text: "마지막 활성봇 -0.2% 초과 손실 중에만,추가매매", value: 'bot_0.2' },
        // { text: "마지막 활성봇 -0.3% 초과 손실 중에만,추가매매", value: 'bot_0.3' },
        // { text: "마지막 활성봇 -0.4% 초과 손실 중에만,추가매매", value: 'bot_0.4' },
        // { text: "마지막 활성봇 -0.5% 초과 손실 중에만,추가매매", value: 'bot_0.5' },
        // { text: "마지막 활성봇 -0.6% 초과 손실 중에만,추가매매", value: 'bot_0.6' },
        // { text: "마지막 활성봇 -0.7% 초과 손실 중에만,추가매매", value: 'bot_0.7' },
        // { text: "마지막 활성봇 -0.8% 초과 손실 중에만,추가매매", value: 'bot_0.8' },
        // { text: "마지막 활성봇 -0.9% 초과 손실 중에만,추가매매", value: 'bot_0.9' },
        // { text: "마지막 활성봇 -1% 초과 손실 중에만,추가매매", value: 'bot_1' },
        // { text: "마지막 활성봇 -1.5% 초과 손실 중에만,추가매매", value: 'bot_1.5' },
        // { text: "마지막 활성봇 -2% 초과 손실 중에만,추가매매", value: 'bot_2' },
        // { text: "마지막 활성봇 -2.5% 초과 손실 중에만,추가매매", value: 'bot_2.5' },
        // { text: "마지막 활성봇 -3% 초과 손실 중에만,추가매매", value: 'bot_3' },
        // { text: "마지막 활성봇 -3.5% 초과 손실 중에만,추가매매", value: 'bot_3.5' },
        // { text: "마지막 활성봇 -4% 초과 손실 중에만,추가매매", value: 'bot_4' },
        // { text: "마지막 활성봇 -4.5% 초과 손실 중에만,추가매매", value: 'bot_4.5' },
        // { text: "마지막 활성봇 -5% 초과 손실 중에만,추가매매", value: 'bot_5' },
        // { text: "마지막 활성봇 -6% 초과 손실 중에만,추가매매", value: 'bot_6' },
        // { text: "마지막 활성봇 -7% 초과 손실 중에만,추가매매", value: 'bot_7' },
        // { text: "마지막 활성봇 -8% 초과 손실 중에만,추가매매", value: 'bot_8' },
        // { text: "마지막 활성봇 -9% 초과 손실 중에만,추가매매", value: 'bot_9' },
        // { text: "마지막 활성봇 -10% 초과 손실 중에만,추가매매", value: 'bot_10' },
        // { text: "마지막 활성봇 -20% 초과 손실 중에만,추가매매", value: 'bot_20' },
        // { text: "마지막 활성봇 -30% 초과 손실 중에만,추가매매", value: 'bot_30' },
        // { text: "마지막 활성봇 -40% 초과 손실 중에만,추가매매", value: 'bot_40' },
        // { text: "마지막 활성봇 -50% 초과 손실 중에만,추가매매", value: 'bot_50' },
        // { text: "마지막 활성봇 -60% 초과 손실 중에만,추가매매", value: 'bot_60' },
        // { text: "마지막 활성봇 -70% 초과 손실 중에만,추가매매", value: 'bot_70' },
        // { text: "마지막 활성봇 -80% 초과 손실 중에만,추가매매", value: 'bot_80' },
        // { text: "마지막 활성봇 -90% 초과 손실 중에만,추가매매", value: 'bot_90' },
        { text: this.$t("global_option_entrybase_type_bot_loss"), value: 'bot' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.05%'}), value: 'bot_0.05' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.06%'}), value: 'bot_0.06' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.07%'}), value: 'bot_0.07' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.08%'}), value: 'bot_0.08' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.09%'}), value: 'bot_0.09' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.1%'}), value: 'bot_0.1' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.2%'}), value: 'bot_0.2' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.3%'}), value: 'bot_0.3' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.4%'}), value: 'bot_0.4' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.5%'}), value: 'bot_0.5' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.6%'}), value: 'bot_0.6' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.7%'}), value: 'bot_0.7' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.8%'}), value: 'bot_0.8' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-0.9%'}), value: 'bot_0.9' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-1%'}), value: 'bot_1' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-1.5%'}), value: 'bot_1.5' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-2%'}), value: 'bot_2' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-2.5%'}), value: 'bot_2.5' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-3%'}), value: 'bot_3' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-3.5%'}), value: 'bot_3.5' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-4%'}), value: 'bot_4' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-4.5%'}), value: 'bot_4.5' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-5%'}), value: 'bot_5' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-6%'}), value: 'bot_6' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-7%'}),  value: 'bot_7' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-8%'}), value: 'bot_8' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-9%'}), value: 'bot_9' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-10%'}), value: 'bot_10' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-20%'}), value: 'bot_20' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-30%'}), value: 'bot_30' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-40%'}), value: 'bot_40' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-50%'}), value: 'bot_50' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-60%'}), value: 'bot_60' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-70%'}), value: 'bot_70' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-80%'}), value: 'bot_80' },
        { text: this.$t("global_option_entrybase_type_bot_txt",{value:'-90%'}), value: 'bot_90' },
        
      ],
      order_type: [
        // { text: "목표달성 시, 즉시 봇 종료 ", value: 'market' },
        // { text: "목표달성 후, 수익고점 대비 0.1% 하락시 종료", value: 'trail_0.1' },
        // { text: "목표달성 후, 수익고점 대비 0.2% 하락시 종료", value: 'trail_0.2' },
        // { text: "목표달성 후, 수익고점 대비 0.3% 하락시 종료", value: 'trail_0.3' },
        // { text: "목표달성 후, 수익고점 대비 0.4% 하락시 종료", value: 'trail_0.4' },
        // { text: "목표달성 후, 수익고점 대비 0.5% 하락시 종료", value: 'trail_0.5' },
        // { text: "목표달성 후, 수익고점 대비 1% 하락시 종료", value: 'trail_1' },
        // { text: "목표달성 후, 수익고점 대비 2% 하락시 종료", value: 'trail_2' },
        // { text: "목표달성 후, 수익고점 대비 3% 하락시 종료", value: 'trail_3' },
        // { text: "목표달성 후, 수익고점 대비 4% 하락시 종료", value: 'trail_4' },
        // { text: "목표달성 후, 수익고점 대비 5% 하락시 종료", value: 'trail_5' },
        { text: this.$t("global_option_order_type_market"), value: 'market' },
        { text: this.$t("global_option_order_type_trail",{value:'0.1%'}), value: 'trail_0.1' },
        { text: this.$t("global_option_order_type_trail",{value:'0.2%'}), value: 'trail_0.2' },
        { text: this.$t("global_option_order_type_trail",{value:'0.3%'}), value: 'trail_0.3' },
        { text: this.$t("global_option_order_type_trail",{value:'0.4%'}), value: 'trail_0.4' },
        { text: this.$t("global_option_order_type_trail",{value:'0.5%'}), value: 'trail_0.5' },
        { text: this.$t("global_option_order_type_trail",{value:'1%'}), value: 'trail_1' },
        { text: this.$t("global_option_order_type_trail",{value:'2%'}), value: 'trail_2' },
        { text: this.$t("global_option_order_type_trail",{value:'3%'}), value: 'trail_3' },
        { text: this.$t("global_option_order_type_trail",{value:'4%'}), value: 'trail_4' },
        { text: this.$t("global_option_order_type_trail",{value:'5%'}), value: 'trail_5' },
      ],
      order_type_profit: [
        // { text: "목표달성 시, 즉시 포지션 종료 ", value: 'market' },
        // { text: "목표달성 후, 수익고점 대비 1% 하락시 종료", value: 'trail_1' },
        // { text: "목표달성 후, 수익고점 대비 2% 하락시 종료", value: 'trail_2' },
        // { text: "목표달성 후, 수익고점 대비 3% 하락시 종료", value: 'trail_3' },
        // { text: "목표달성 후, 수익고점 대비 4% 하락시 종료", value: 'trail_4' },
        // { text: "목표달성 후, 수익고점 대비 5% 하락시 종료", value: 'trail_5' },
        // { text: "목표달성 후, 수익고점 대비 6% 하락시 종료", value: 'trail_6' },
        // { text: "목표달성 후, 수익고점 대비 7% 하락시 종료", value: 'trail_7' },
        // { text: "목표달성 후, 수익고점 대비 8% 하락시 종료", value: 'trail_8' },
        // { text: "목표달성 후, 수익고점 대비 9% 하락시 종료", value: 'trail_9' },
        // { text: "목표달성 후, 수익고점 대비 10% 하락시 종료", value: 'trail_10' },
        // { text: "목표달성 후, 수익고점 대비 15% 하락시 종료", value: 'trail_15' },
        // { text: "목표달성 후, 수익고점 대비 20% 하락시 종료", value: 'trail_20' },
        // { text: "목표달성 후, 수익고점 대비 30% 하락시 종료", value: 'trail_30' },
        // { text: "목표달성 후, 수익고점 대비 40% 하락시 종료", value: 'trail_40' },
        // { text: "목표달성 후, 수익고점 대비 50% 하락시 종료", value: 'trail_50' },
        { text: this.$t("global_option_order_type_market"), value: 'market' },
        { text: this.$t("global_option_order_type_trail",{value:'1%'}), value: 'trail_1' },
        { text: this.$t("global_option_order_type_trail",{value:'2%'}), value: 'trail_2' },
        { text: this.$t("global_option_order_type_trail",{value:'3%'}), value: 'trail_3' },
        { text: this.$t("global_option_order_type_trail",{value:'4%'}), value: 'trail_4' },
        { text: this.$t("global_option_order_type_trail",{value:'5%'}), value: 'trail_5' },
        { text: this.$t("global_option_order_type_trail",{value:'6%'}), value: 'trail_6' },
        { text: this.$t("global_option_order_type_trail",{value:'7%'}), value: 'trail_7' },
        { text: this.$t("global_option_order_type_trail",{value:'8%'}), value: 'trail_8' },
        { text: this.$t("global_option_order_type_trail",{value:'9%'}), value: 'trail_9' },
        { text: this.$t("global_option_order_type_trail",{value:'10%'}), value: 'trail_10' },
        { text: this.$t("global_option_order_type_trail",{value:'15%'}), value: 'trail_15' },
        { text: this.$t("global_option_order_type_trail",{value:'20%'}), value: 'trail_20' },
        { text: this.$t("global_option_order_type_trail",{value:'30%'}), value: 'trail_30' },
        { text: this.$t("global_option_order_type_trail",{value:'40%'}), value: 'trail_40' },
        { text: this.$t("global_option_order_type_trail",{value:'50%'}), value: 'trail_50' },
      ],

      funding_type: [
        { text: "펀딩피 지급시 잔고로 회수", value: 'balance' },
        { text: "펀딩피 지급시 봇으로 등록", value: 'bot' },
      ],


      amount_type: [
        // { text: "지정수량 고정 매매", value: 'normal' },
        // { text: "지정수량 x 봇 수량 매매 (다소위험)", value: 'amount_x_bot' },
        // { text: "포지션 x 봇 수량 매매 (매우위험)", value: 'position_x_bot' },
        { text: this.$t("global_option_amount_type_normal"), value: 'normal' },
        { text: this.$t("global_option_amount_type_amount_x_bot"), value: 'amount_x_bot' },
        { text: this.$t("global_option_amount_type_position_x_bot"), value: 'position_x_bot' },
      ],

      sync_type: [
        // { text: "비중조절 안함", value: false },
        // { text: "반대쪽 비중보다 작다면, 더해서 매매", value: 'sync-plus' },
        // { text: "반대쪽 비중보다 크다면, 줄여서 매매", value: 'sync-minus' },
        // { text: "반대쪽 비중보다 작거나 크면 가감하여 매매", value: 'sync-both' },
        { text: this.$t("global_option_sync_type_none"), value: false },
        { text: this.$t("global_option_sync_type_sync_plus"), value: 'sync-plus' },
        { text: this.$t("global_option_sync_type_sync_minus"), value: 'sync-minus' },
        { text: this.$t("global_option_sync_type_sync_both"), value: 'sync-both' },
      ],
      
      liquidation_type: [
        { text: "청산대비 안함", value: false },
        { text: "청산대비 - 청산가 50% 위에서 반대 포지션 지정수량 봇 오픈", value: 'position-open_50' },
        { text: "청산대비 - 청산가 40% 위에서 반대 포지션 지정수량 봇 오픈", value: 'position-open_40' },
        { text: "청산대비 - 청산가 30% 위에서 반대 포지션 지정수량 봇 오픈", value: 'position-open_30' },
        { text: "청산대비 - 청산가 20% 위에서 반대 포지션 지정수량 봇 오픈", value: 'position-open_20' },
        { text: "청산대비 - 청산가 10% 위에서 반대 포지션 지정수량 봇 오픈(위험)", value: 'position-open_10' },
        { text: "청산대비 - 청산가 5% 위에서 반대 포지션 지정수량 봇 오픈(매우위험)", value: 'position-open_5' },
        { text: "청산대비 - 청산가 4% 위에서 반대 포지션 지정수량 봇 오픈(매우위험)", value: 'position-open_4' },
        { text: "청산대비 - 청산가 3% 위에서 반대 포지션 지정수량 봇 오픈(매우위험)", value: 'position-open_3' },
        { text: "청산대비 - 청산가 2% 위에서 반대 포지션 지정수량 봇 오픈(매우위험)", value: 'position-open_2' },
        { text: "청산대비 - 청산가 1% 위에서 반대 포지션 지정수량 봇 오픈(매우위험)", value: 'position-open_1' },
        { text: "청산대비 - 청산가 50% 위에서 현재 포지션 오래된 봇 종료", value: 'position-close_50' },
        { text: "청산대비 - 청산가 40% 위에서 현재 포지션 오래된 봇 종료", value: 'position-close_40' },
        { text: "청산대비 - 청산가 30% 위에서 현재 포지션 오래된 봇 종료", value: 'position-close_30' },
        { text: "청산대비 - 청산가 20% 위에서 현재 포지션 오래된 봇 종료", value: 'position-close_20' },
        { text: "청산대비 - 청산가 10% 위에서 현재 포지션 오래된 봇 종료(위험)", value: 'position-close_10' },
        { text: "청산대비 - 청산가 5% 위에서 현재 포지션 오래된 봇 종료(매우위험)", value: 'position-close_5' },
        { text: "청산대비 - 청산가 4% 위에서 현재 포지션 오래된 봇 종료(매우위험)", value: 'position-close_4' },
        { text: "청산대비 - 청산가 3% 위에서 현재 포지션 오래된 봇 종료(매우위험)", value: 'position-close_3' },
        { text: "청산대비 - 청산가 2% 위에서 현재 포지션 오래된 봇 종료(매우위험)", value: 'position-close_2' },
        { text: "청산대비 - 청산가 1% 위에서 현재 포지션 오래된 봇 종료(매우위험)", value: 'position-close_1' },
      ],


      position: null,

      globalTicker : null,


      preset_option :null,
      preset_option_tmp :null,

      preset_amount : null,
    };
  },
  watch: {
    botoption_json(botoption_json) {
      //console.log(botoption_json);
      this.$localData('botOption',botoption_json)
    },
    async '$store.state.ticker_update'(){

      this.globalTicker = this.$getTickerInfo(this.getBotInfo(this.bot_id).symbol);



      ///console.log('tttttttttt');
      this.getPosition();

    },

    
    dialog(value){
      if(value){
        this.initPresetOption();
      }
    },

  },
  computed: {


    my_bot_list(){
      const self = this;
      //const sidelist = this.$_.filter(this.$store.state.botlist.open, {'side': this.getBotInfo(this.bot_id).side });
      const sidelist = this.$_.filter(this.$store.state.botlist.open, function(item) { return item.side == self.getBotInfo(self.bot_id).side  && item.symbol == self.$store.state.systemOption.symbol  && item._can_close_amount > 0 && item.status != 'close'; });
      if(sidelist){
          return sidelist
      }else{
        return []
      }
    },


    botoption(){
      return this.$store.state.botOption;
    },
    botoption_json(){
      return JSON.stringify(this.$store.state.botOption);
    },
    
  },
  mounted() {
    //const self = this;






    /*asdf*/

    let agreement = this.$nvl(this.$localData('agreement'),false);
    if(agreement){
      this.dialog = false;
    }else{
      this.dialog = true;
    }


      this.getPosition();



      if(!this.$nvl(this.getBotInfo(this.bot_id).catch.ordertype_profit,false)){
        this.getBotInfo(this.bot_id).catch.ordertype_profit = 'market';

      }

  },
  methods: {
    initPresetOption(){

          this.preset_option_tmp = JSON.stringify(this.getPresetInfo(this.side,this.preset_id));
          this.preset_option = JSON.parse(this.preset_option_tmp);

          if(this.preset_no == 1){
                this.preset_amount = this.getBotInfo(this.bot_id).transition_1_amount;
          }
          if(this.preset_no == 2){
                this.preset_amount = this.getBotInfo(this.bot_id).transition_2_amount;
          }
    },
    savePresetOption(){

      let presetOptions = this.$store.state.presetOption[this.side];
      const targetIndex = this.$_.findIndex(presetOptions,{'id': this.preset_id});

      presetOptions[targetIndex] = this.preset_option;
      //console.log('presetOptions[presetOptions]',presetOptions[targetIndex]);




      if(this.preset_no == 1){
            this.getBotInfo(this.bot_id).transition_1_amount = parseFloat(this.preset_amount);
      }
      if(this.preset_no == 2){
            this.getBotInfo(this.bot_id).transition_2_amount = parseFloat(this.preset_amount);
      }
    
    let presetOption_both = this.$store.state.presetOption;
       presetOption_both[this.side] = presetOptions;
      console.log('presetOptions',presetOption_both);

presetOption_both.time =this.$moment().format("yyyy-MM-DD H:mm:ss");
      
        this.$localData('presetOption',presetOption_both);
        this.$store.dispatch("UPDATE_PRESET", presetOption_both);

      
      this.dialog = false;
    },

    delayMinMax(){
      if(this.getBotInfo(this.bot_id).limit.time  < 0.5){
          this.getBotInfo(this.bot_id).limit.time = 0.5;
      }
    },


    amountMinMax(){
      const coinInfo =this.$getCoinInfo(this.getBotInfo(this.bot_id).symbol);

      if(this.preset_amount < coinInfo.limits.amount.min){
          this.preset_amount = coinInfo.limits.amount.min;
      }
      if(this.preset_amount > coinInfo.limits.amount.max){
          this.preset_amount = coinInfo.limits.amount.max;
      }
    },

    absMinMax(){

      this.getBotInfo(this.bot_id).catch.candle_max = Math.abs(this.getBotInfo(this.bot_id).catch.candle_max);
      if(this.getBotInfo(this.bot_id).catch.candle_max == 0){
          this.getBotInfo(this.bot_id).catch.candle_max = '';        
      }

      this.getBotInfo(this.bot_id).catch.candle_min = Math.abs(this.getBotInfo(this.bot_id).catch.candle_min);
      if(this.getBotInfo(this.bot_id).catch.candle_min == 0){
          this.getBotInfo(this.bot_id).catch.candle_min = '';        
      }

      this.getBotInfo(this.bot_id).catch.candle_last_max = Math.abs(this.getBotInfo(this.bot_id).catch.candle_last_max);
      if(this.getBotInfo(this.bot_id).catch.candle_last_max == 0){
          this.getBotInfo(this.bot_id).catch.candle_last_max = '';        
      }

      this.getBotInfo(this.bot_id).catch.candle_last_min = Math.abs(this.getBotInfo(this.bot_id).catch.candle_last_min);
      if(this.getBotInfo(this.bot_id).catch.candle_last_min == 0){
          this.getBotInfo(this.bot_id).catch.candle_last_min = '';        
      }


      this.getBotInfo(this.bot_id).catch.rsi1_length = Math.abs(this.getBotInfo(this.bot_id).catch.rsi1_length);
      if(this.getBotInfo(this.bot_id).catch.rsi1_length == 0){
          this.getBotInfo(this.bot_id).catch.rsi1_length = '';        
      }
      this.getBotInfo(this.bot_id).catch.rsi2_length = Math.abs(this.getBotInfo(this.bot_id).catch.rsi2_length);
      if(this.getBotInfo(this.bot_id).catch.rsi2_length == 0){
          this.getBotInfo(this.bot_id).catch.rsi2_length = '';        
      }
      this.getBotInfo(this.bot_id).catch.rsi1_min = Math.abs(this.getBotInfo(this.bot_id).catch.rsi1_min);
      if(this.getBotInfo(this.bot_id).catch.rsi1_min == 0){
          this.getBotInfo(this.bot_id).catch.rsi1_min = '';        
      }
      this.getBotInfo(this.bot_id).catch.rsi2_min = Math.abs(this.getBotInfo(this.bot_id).catch.rsi2_min);
      if(this.getBotInfo(this.bot_id).catch.rsi2_min == 0){
          this.getBotInfo(this.bot_id).catch.rsi2_min = '';        
      }
      this.getBotInfo(this.bot_id).catch.rsi1_max = Math.abs(this.getBotInfo(this.bot_id).catch.rsi1_max);
      if(this.getBotInfo(this.bot_id).catch.rsi1_max == 0){
          this.getBotInfo(this.bot_id).catch.rsi1_max = '';        
      }
      this.getBotInfo(this.bot_id).catch.rsi2_max = Math.abs(this.getBotInfo(this.bot_id).catch.rsi2_max);
      if(this.getBotInfo(this.bot_id).catch.rsi2_max == 0){
          this.getBotInfo(this.bot_id).catch.rsi2_max = '';        
      }

    },


        oneClick(event) {
          this.clicks++;
          if (this.clicks === 1) {
            this.timer = setTimeout( () => {
              this.result.push(event.type);
              this.clicks = 0
            }, this.delay);
          } else {
             clearTimeout(this.timer);  
             this.result.push('dblclick');
             this.clicks = 0;

             this.getBotInfo(this.bot_id).active.catch = !this.getBotInfo(this.bot_id).active.catch;

          }         
        },


    getBotInfo(id){
      return this.$_.find(this.$store.state.botOption, {'id': id});
    },

    getPresetInfo(side,id){
      //return this.$store.state.presetOption[side];
      return this.$_.find(this.$store.state.presetOption[side], {'id': id});
    },


    buttonOk: function () {
      this.$localData('agreement',this.$moment());
      this.dialog = false;
    },




    getPosition(){
      //const systemSymbolOnlyStr = this.getBotInfo(this.bot_id).symbol.replace("/", '');
      const side = this.$_.capitalize(this.getBotInfo(this.bot_id).side);
      /*
      const position =  this.$_.find(this.$store.state.position,function(item) {
        return item.data.symbol == systemSymbolOnlyStr && item.data.side == side});
        */
      const position = this.$getPosition(this.getBotInfo(this.bot_id).symbol,side,true);
        
        this.position = position;
    },


    amount_x_bot(){
        let amount_x_bot = 0
        if(this.position){
            amount_x_bot = this.getBotInfo(this.bot_id).amount * (this.$nvl(this.my_bot_list.length,0)+1);
        }
        amount_x_bot = this.$nvl(amount_x_bot,this.getBotInfo(this.bot_id).amount);

      return amount_x_bot;
    },


    position_x_bot(){
        let position_x_bot = 0
        if(this.position){
            position_x_bot = this.$nvl(parseFloat(this.position.data.size),0) * (this.$nvl(this.my_bot_list.length,0)+1);
        }
        position_x_bot = this.$nvl(position_x_bot,this.getBotInfo(this.bot_id).amount);

      return position_x_bot;
    },


calcAmount(type){
        let canadmount = 0
  if(type == 'position_x_bot'){
        if(this.position && this.globalTicker){
          canadmount = (this.$nvl(parseFloat(this.position.data.size),0) * (this.$nvl(this.my_bot_list.length,0)+1)*this.globalTicker.close) / this.position.data.leverage
        }
  }
  if(type == 'amount_x_bot'){
        if(this.position && this.globalTicker){
          canadmount = (this.$nvl(parseFloat(this.getBotInfo(this.bot_id).amount),0) * (this.$nvl(this.my_bot_list.length,0)+1)*this.globalTicker.close )  / this.position.data.leverage
        }
  }
  if(type == 'normal' && this.position){
          canadmount = (this.$nvl(parseFloat(this.getBotInfo(this.bot_id).amount),0) *this.globalTicker.close) / this.position.data.leverage
  }
  

  return this.$setFloat(parseFloat(canadmount),2,true)
},





  }
};
</script>
<style lang="scss" scoped>
</style>
