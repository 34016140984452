<template>
  <v-card style="min-height:72px;">

    <v-tabs v-model="$store.state.symbol_focus" 
    v-if="loaded"
         :show-arrows="true"
      icons-and-text>
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab v-for="(item, index) in symbols" :key="item.id" :index="index" 
      :style="$store.state.symbol_focus == index ? 'position:relative;padding-left:25px;padding-right:25px;background:#f8f8f8;':'position:relative;padding-left:25px;padding-right:25px;'">
        <div>

          <small v-if="symbol_list">
            <strong v-if="symbol_list[index] && symbol_list[index].ticker">
              {{symbol_list[index].ticker.last}}
              </strong>
          </small>

          <strong style="display:block;padding:5px 0;">
            {{ item }}
          </strong>


                      <v-chip x-small 
                      :color="$getBotInfo(item+'-buy').active.catch ? 'teal accent-3': 'grey lighten-4'"
                      :style="$getBotInfo(item+'-buy').active.catch? 'font-size:8px;margin-right:2px;padding:4px 7px 2px;color:#fff;font-weigh:bold;':'font-size:8px;margin-right:2px;padding:5px 7px;color:#888;'">
                        
                        BUY
                      </v-chip>
                      
                      <v-chip x-small 
                      :color="$getBotInfo(item+'-sell').active.catch ? 'red accent-1': 'grey lighten-4'"
                      :style="$getBotInfo(item+'-sell').active.catch? 'font-size:8px;margin-right:2px;padding:4px 7px 2px;color:#fff;font-weigh:bold;':'font-size:8px;margin-right:2px;padding:5px 7px;color:#888;'">
                       
                        SELL
                      </v-chip>

        </div>
      </v-tab>
    </v-tabs>
  </v-card>
</template>
<script>
export default {
  name: "symboltab",
  title: "symboltab",
  props: {
    symbols: { required: false, default: null },

  },
  components: {},
  data() {
    return {
    symbol_list : [],
    loaded:true

    };
  },
  watch: {
     //꺼도 갱신되는 듯. 0517 성능이슈로 사용x
     /*
    '$store.state.symbol_focus'(){
      this.loaded = false;
      this.loaded = true;
    },*/
    
    '$store.state.ticker_update'(){

          this.symbol_list =this.$localData('symbolList');
          //console.log('this.$store.state.symbol_list',this.symbol_list);
    }

  },
  computed: {

  },
  methods: {
    tickerInfo(symbol){
      return this.$getTickerInfo(symbol)
    }

  },

  created() {
    this.loaded = false;
    setTimeout(() => {
      this.loaded = true;
    }, 300);


  },
};
</script>
<style lang="scss" scoped>
</style>
