<template>
  <v-dialog
    v-model="dialog"
    :max-width="maxWidth"
    @click:outside="onClickOutside"
    :persistent="persistent"
  >
    <v-card>
      <v-card-title class="justify-center mb-4" style="font-size: 2rem">
        <div>{{ emoji }}</div>
      </v-card-title>
      <v-card-subtitle
        class="text-center pb-2 font-weight-bold;"
        style="font-size: 17px; font-weight: bold"
      >
        {{ title }}
      </v-card-subtitle>
      <v-card-text :class="textAlign">
        <div v-if="firstLineText" class="mt-3" v-html="firstLineText"></div>
        <div v-if="secondLineText" class="mt-3" v-html="secondLineText"></div>
        <div v-if="thirdLineText" class="mt-3" v-html="thirdLineText"></div>
      </v-card-text>

      <v-card-actions v-if="button1 || button2">
        <v-spacer></v-spacer>

        <v-btn
          v-if="button1"
          :color="button1Color"
          text
          @click="onClickButton(button1, button1Payload)"
        >
          {{ button1Text }}
        </v-btn>

        <v-btn
          :disabled="!loaded"
          v-if="button2"
          :color="button2Color"
          text
          @click="onClickButton(button2, button2Payload)"
        >
          {{ button2Text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  // props: ["title", "firstLineText", "secondLineText", "ThirdLineText", "btnText", "dialog"],
  props: {
    dialog: Boolean,
    timeout: {
      type: Number,
      default: 1000,
    },

    emoji: String,
    title: String,
    firstLineText: String,
    secondLineText: String,
    thirdLineText: String,
    textAlign:  {
      type: String,
      default: "text-center",
    },

    button1Text: {
      type: String,
      default: "취소",
    }, //버튼1 이름
    button1: String, //commit function name
    button1Payload: null, //any type
    button1Color: String,
    button1Class: String,

    button2Text: {
      type: String,
      default: "확인",
    }, //버튼2 이름
    button2: String, //commit function name
    button2Payload: {}, //any type
    button2Color: String,
    button2Class: String,
    persistent: {
      type: Boolean,
      default: false,
    },
    maxWidth:  {
      type: Number,
      default: 250,
    },

  },


  data() {
    return {
    loaded:false,
    }
  },

  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 300);


    if (this.timeout) {
      setTimeout(() => {
        this.$store.dispatch("closeAlertDialog");
        
      }, this.timeout);
    }
  },
  // destroyed() {
  //   console.log("destoyed")
  // },
  watch: {

/*
    async '$store.state.position_update'() {
      this.loaded = true;
    },
    async '$store.state.ticker_update'() {
      this.loaded = true;
    },
*/


  },
  methods: {
    onClickOutside() {
      if (!this.persistent) {
        this.$store.dispatch("closeAlertDialog");
      }
    },


    async closeAllOpenBots(mutation, payload) {
          const self = this;
          const getPosition = await this.getPosition(payload);
          self.$closePositionPossible(getPosition.data.size,`수동으로 ${payload.side} 모든 오픈 포지션 종료`,payload.symbol,payload.side);
          /*
      
            let botlist = this.$nvl(this.$localData('botList-'+payload.symbol),{open:[],close:[],trail:[]});

            
            const targetBots = this.$_.filter(botlist['open'],{'side': payload.side });



            targetBots.forEach(async function (item) {
                await self.$sleep(self.$store.state.exchange.rateLimit); // milliseconds

                          let payload_tmp = payload;
                          payload_tmp.amount = item.amount;
                        console.log('targetbots',item);
                        console.log('targetbots payload_tmp',payload_tmp);
                        

                          const orderResult = await self.$store.dispatch(mutation, payload_tmp);


                                let _msg = `수동으로 ${payload_tmp.side} 모든 오픈 포지션 종료`;
                                let open_price = item.open_price;
                                let open_id = item.open_id; //모든 포지션 종료 요청일 경우, open_id값이 비어있음.
                                let close_id = orderResult.id;//종료형 요청일 경우, close_id가 orderresult의값
                           



                              if(orderResult){
                                  self.$addLog({
                                    symbol : orderResult.symbol, //symbol
                                    side : payload_tmp.side, //side
                                    type : 'manual', //type
                                    msg : _msg, //msg
                                    amount : orderResult.amount,
                                    open_id : open_id,//open_id
                                    open_price : open_price,//open_id
                                    close_id : close_id,//close_id
                                    close_price :'',//open_id 오더 실행 직후에는 비어있는값,
                                    clear : payload_tmp._is_clear,
                                    payload : payload_tmp,
                                    status : payload_tmp.status,
                                  }); 

                              }
                              

            });
*/
             return false;
             
    },




    async onClickButton(mutation, payload) {
      const self = this;

      this.loaded = false;

      if (mutation != "update" && mutation != "exit" && mutation != "close" && mutation != "goUrl" && mutation != "resetSettings" && mutation != "fileSave" && mutation != "fileLoad" && mutation != "asyncPosition" && mutation != "externalLink") {
        //this.$store.commit(mutation, payload);






            //모든봇 종료일경우,
            if(payload._is_all_open){
              this.$store.dispatch("closeAlertDialog");
              this.closeAllOpenBots(mutation, payload);
              return false;
            }
            //모든봇 종료일경우,


//크론을 멈춰서 진입하는 것은 딜레이생겨서 실패..
        //this.$store.state.crontab_puase = 'stop';
        //console.log('this.$store.state.crontab_puase S',this.$store.state.crontab_puase);



        /**
         * 봇업데이트
         * 버튼을 눌러 오더를 실행하면, 그에맞는 봇을 생성할 준비한다.
         * updateBot이라는 로컬스토리지에 넣었다가, 주기적으로 오더리스트와
         * 
         */
        const orderResult = await this.$store.dispatch(mutation, payload);


        //this.$store.state.crontab_puase = 'start';
        ///console.log('this.$store.state.crontab_puase E',this.$store.state.crontab_puase);

/*
        let updateBot = this.$localData('updateBot');
        if(this.$nvl(updateBot,false) && this.$nvl(orderResult,false)){
          updateBot.push(orderResult);
        }else{
          updateBot = [orderResult];
        }
         this.$localData('updateBot',updateBot);
*/

        let _msg = `수동으로 ${payload.side} 포지션진입 주문요청 접수`;
let open_id = orderResult.id;
let close_id = ''; //기본은 open형이므로, close_id가 없음.
let open_price = '';
        if(orderResult && orderResult.info.reduce_only){
          //종료형 요청일 경우, reduce_only로 요청하고있음.
          _msg = `수동으로 ${payload.side} 포지션 종료`;
          open_price = payload.open_price;
          open_id = payload.open_id; //종료형 요청일 경우, open_id가 payload의 값
          close_id = orderResult.id; //종료형 요청일 경우, close_id가 orderresult의값
        }

        if(payload._is_clear){
          _msg = `수동으로 ${payload.side} 모든 포지션 종료`;
          open_price = payload.open_price;
          open_id = ''; //모든 포지션 종료 요청일 경우, open_id값이 비어있음.
          close_id = orderResult.id;//종료형 요청일 경우, close_id가 orderresult의값
          

          this.$clearBot('open',payload);
          this.$clearBot('close',payload);
          this.$clearBot('trail',payload);
        }



        if(orderResult){
            this.$addLog({
              symbol : orderResult.symbol, //symbol
              side : payload.side, //side
              type : 'manual', //type
              msg : _msg, //msg
              amount : orderResult.amount,
              open_id : open_id,//open_id
              open_price : open_price,//open_id
              close_id : close_id,//close_id
              close_price :'',//open_id 오더 실행 직후에는 비어있는값,
              clear : payload._is_clear,
              payload : payload,
              status : payload.status,
            }); 

        }






        /**
         * 봇업데이트
         * 버튼을 눌러 오더를 실행하면, 그에맞는 봇을 생성할 준비한다.
         * updateBot이라는 로컬스토리지에 넣었다가, 주기적으로 오더리스트와
         * 
         */





        this.$store.dispatch("closeAlertDialog");
      }

      if (mutation == "goUrl") {
        //this.$store.commit(mutation, payload);
        this.goUrl(payload);
      }

      if (mutation == "resetSettings") {
        //this.$store.commit(mutation, payload);
        this.$resetSettings();

      }


      if (mutation == "fileSave") {
        //this.$store.commit(mutation, payload);
        //this.$fileSave(payload);
        this.$downloadSettings(payload);
      }


      if (mutation == "fileLoad") {
        //this.$store.commit(mutation, payload);
        this.$fileLoad();
      }

      if (mutation == "exit") {
        //this.$store.commit(mutation, payload);
        this.$buttonClose();
        //this.$buttonMinimize();
      }

      if (mutation == "externalLink") {
        //this.$store.commit(mutation, payload);
        this.$externalLink(payload)
        //this.$buttonMinimize();
      }

      if (mutation == "update") {

          setTimeout(() => {
            let alertDialogInfo = {
              timeout: 3000,
              persistent:true,
              //emoji: "😆",
              // title: "종료 중...",
              // firstLineText: "실행중인 프로그램을 종료합니다.",
              // secondLineText: "다운로드 페이지로 이동합니다.",
              title: self.$t('global_dialog_upgrading_title'),
              firstLineText: self.$t('global_dialog_upgrading_firstLineText'),
              secondLineText: self.$t('global_dialog_upgrading_secondLineText'),
              // thirdLineText: "셋째줄",
              };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);
        }, 100);


        //this.$store.commit(mutation, payload);
           // this.$externalLink('https://crypto25.notion.site/2-2535edab57964194b459f9284dca0cd9');
           this.$externalLink(this.$store.state.serverinfo.url_app_banner_guide);

           /*

    let _thisLang = this.$localData('lang');
    if(!_thisLang){
      _thisLang = this.$localData('lang',navigator.language);
    }

      if(_thisLang == 'ko'){
            this.$externalLink('https://crypto25.notion.site/2-2535edab57964194b459f9284dca0cd9');    
      }else{
          this.$externalLink("https://crypto25.notion.site/2-Download-and-Install-ca97265e3c5e44f4a49d08714f7dbb8d");
      }
*/



        //this.$buttonClose();
          setTimeout(() => {
            this.$buttonClose();  
        }, 3000);
        //this.$buttonMinimize();
      }


      if (mutation == "close") {
              this.$store.dispatch("closeAlertDialog");
          if(this.$nvl(payload,false) && payload.notice_refresh){
              this.$store.state.serverinfo.notice = ''; //watch로 노티스를 다시 보여주기 위함임.


          }


      }


      if (mutation == "asyncPosition") {
        //this.$store.commit(mutation, payload);
        this.$asyncPosition(payload);

        this.$store.dispatch("closeAlertDialog");
      }



      this.onClickOutside();
    },




    async getPosition(payload){
        console.log('orderResult getPosition', payload);
      const side = this.$_.capitalize(payload.side);
     // const symbol = payload.symbol.replace("/", '');
      /*
      const position =  this.$_.find(this.$store.state.position,function(item) {
        return item.data.symbol == symbol && item.data.side == side});
*/
//공통 포지션함수로 변경
      let position = this.$getPosition(payload.symbol,side,true);


        
        console.log('orderResult getPosition 2', this.$store.state.position);
        return position;
    },

    
  },
};
</script>

<style></style>
