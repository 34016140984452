<template>
  <v-card style="min-height:72px;">


<v-sheet
    class="mx-auto"
    elevation="0"
  >
    <v-slide-group
      v-model="$store.state.symbol_focus" 
      class="pa-0"
      mandatory
      show-arrows
    >
      <v-slide-item
        v-for="(item, index) in symbols" :key="item.id" :index="index"
        v-slot="{  toggle }"

      :style="$store.state.symbol_focus == index ? 'border-radius:4px;;position:relative;padding-left:25px;padding-right:25px;background:#f8f8f8;':'position:relative;padding-left:25px;padding-right:25px;'"

      >
         <div @click="toggle" 
         :style="$store.state.symbol_focus == index?'color:#1976d2;text-align:center;cursor:pointer;padding-bottom:7px;':'text-align:center;cursor:pointer;padding-bottom:7px;color:#888;font-weight:normal;'">

          <small v-if="symbol_list" style="display:block;height:20px;margin:6px 0 0px;font-size:12px;">
            <strong v-if="symbol_list[index] && symbol_list[index].ticker">
              {{symbol_list[index].ticker.last}}
            </strong>

          </small>

          <strong 
          :style="$store.state.symbol_focus == index?'display:block;padding:0px 0;color:#1976d2;font-size:14px;':'display:block;padding:0px 0;color:#888;font-size:14px;'">
            {{ item }}
          </strong>


                      <v-chip x-small 
                      :color="$getBotInfo(item+'-buy').active.catch ? 'teal accent-3': 'grey lighten-4'"
                      :style="$getBotInfo(item+'-buy').active.catch? 'font-size:8px;margin-right:2px;padding:4px 7px 2px;color:#fff;font-weigh:bold;':'font-size:8px;margin-right:2px;padding:5px 7px;color:#888;'">
                        
                        BUY
                      </v-chip>
                      
                      <v-chip x-small 
                      :color="$getBotInfo(item+'-sell').active.catch ? 'red accent-1': 'grey lighten-4'"
                      :style="$getBotInfo(item+'-sell').active.catch? 'font-size:8px;margin-right:2px;padding:4px 7px 2px;color:#fff;font-weigh:bold;':'font-size:8px;margin-right:2px;padding:5px 7px;color:#888;'">
                       
                        SELL
                      </v-chip>

            <div style="border-radius:4px;background:#1976d2;position:absolute;left:0;bottom:0;right:0;height:3px;" v-show="$store.state.symbol_focus == index"></div>
        </div>



      </v-slide-item>
    </v-slide-group>
  </v-sheet>


  </v-card>
</template>
<script>
export default {
  name: "symboltab",
  title: "symboltab",
  props: {
    symbols: { required: false, default: null },

  },
  components: {},
  data() {
    return {
    symbol_list : [],
    loaded:true

    };
  },
  watch: {
     //꺼도 갱신되는 듯. 0517 성능이슈로 사용x
     /*
    '$store.state.symbol_focus'(){
      this.loaded = false;
      this.loaded = true;
    },*/
    
    '$store.state.ticker_update'(){

          this.symbol_list =this.$localData('symbolList');
          //console.log('this.$store.state.symbol_list',this.symbol_list);
    }

  },
  computed: {

  },
  methods: {
    tickerInfo(symbol){
      return this.$getTickerInfo(symbol)
    }

  },

  created() {
    this.loaded = false;
    setTimeout(() => {
      this.loaded = true;
    }, 300);


  },
};
</script>
<style lang="scss" scoped>
</style>
