import axios from 'axios'
//import config from 'config';
//import moment from "moment";
//const indicators = require('technicalindicators')
//import { WebsocketClient }from 'bybit-api';

import { RSI } from 'technicalindicators';

export default {
  install(Vue) {

    Vue.prototype.$axios = axios;

    // sleep
    Vue.prototype.$sleep = (ms)=> new Promise (resolve => setTimeout (resolve, ms));


    // NVL
    Vue.prototype.$nvl = function (str, defaultStr) {
      if (typeof str == "undefined" || str == null || str == "") str = defaultStr;
      return str;
    };

    // addComma
    Vue.prototype.$addComma = function (num) {
      let str = String(num);
      //return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
      if(num > 10){
        return Number(str).toLocaleString('en');
      }else{
        return num
      }

    };

    // unsetComma
    Vue.prototype.$unsetComma = function (str) {
      let num = String(str);
      return num.replace(/[^\d]+/g, '');
    };


/***
 * 소수점 숫자 자릿수 제한
 ***/
    Vue.prototype.$setFloat = function (c, b, fixed) {


      if(this.$nvl(this.$store.state.ticker,false) && !this.$nvl(fixed,false)){
        if(
          this.$store.state.ticker.symbol =='SAND/USDT' ||
          this.$store.state.ticker.symbol =='XRP/USDT' ||
          this.$store.state.ticker.symbol =='AVAX/USDT' ||
          this.$store.state.ticker.symbol =='ATOM/USDT'){
            b = 5;        
        }
      }


      var a = String(c); - 1 != a.indexOf("e") && (a = "0.0");
      a = a.replace(/[^0-9.-]/gi, "");
      isNaN(parseFloat(a)) && (a = "0");
      if (-1 != a.indexOf(".")) {
        if (a = a.split("."), void 0 == b) return a[0]
      } else return a;
      if (0 == b) return a[0];
      if (a[1].length >= b) a[1] = a[1].substr(0, b);
      else {
        for (var g = b - a[1].length, f = "", h = 0; h < g; h++) f += "0";
        a[1] += f
      }
      return a.join(".")
    };


    Vue.prototype.$setBr = function (str) {
      return str.replace(/\n/g, "<br />");
    }



    // isJsonString
    Vue.prototype.$isJsonString = function (str) {
      try {
        var json = JSON.parse(str);
        return (typeof json === 'object');
      } catch (e) {
        return false;
      }
    };

    // timestmptodate
    Vue.prototype.$timestampToDate = function (timestamp) {
      var d = new Date(timestamp);

      return d.getFullYear() +'-'+ (d.getMonth()+1) + '-' +d.getDate() ;
    };



/**
 * 퍼센트구하기
 * 전체값에서 일부값은 몇 퍼센트? 
 */
 Vue.prototype.$calcPercentValue = function (totalNum, partNum) {
  //일부값 ÷ 전체값 X 100
  var _result = partNum / totalNum * 100;
  return _result
};

/**
 * 값 구하기
 * 전체값의 몇 퍼센트는 얼마? 
 */
 Vue.prototype.$calcPercentValue = function (totalNum, partPer) {
  //전체값 X 퍼센트 ÷ 100
  var _result = totalNum * partPer / 100;
  return _result
};


/**
 * 값 퍼센트로 가감하기
 * 전체값의 %가감된 값은 얼마?
 */
/*
    Vue.prototype.$calcPercentValue = function (totalNum, partPer) {
      var _result
    
          _result = totalNum * (1 + partPer / 100) ;
    
      return _result
    };

*/
/**
 * 퍼센트구하기
 * 전체값에서 비교값의 퍼센트 차이
 */
    Vue.prototype.$calcPercentDiffold = function (firstNum, secondNum) {
      var _result = ((secondNum/firstNum)-1)*100 ;
      return _result
    };

    Vue.prototype.$calcPercentDiff2 = function (a, b) {
      return  ( a<b ? "-" + ((b - a) * 100) / a : ((a - b) * 100) / b ) + "%";
      //return  100 * Math.abs( ( a - b ) / ( (a+b)/2 ) );
     }
     Vue.prototype.$calcPercentDiff2 = function (a, b) {
       return  100 * Math.abs( ( a - b ) / ( (a+b)/2 ) );
      }

      Vue.prototype.$calcPercentDiff = function (b, a) {
  
        return  (100-((parseFloat(b) ) / parseFloat(a)*100 ))

        }
        Vue.prototype.$calcPercentDiff3 = function (b, a) {
    
          //return  ( a<b ? "-" + ((b - a) * 100) / a : ((a - b) * 100) / b ) + "%";
            let result = 0;
            if(a<b){
              result = - ( ((b - a) * 100) / a)
            }else{
              result = ((a - b) * 100) / b 
            }
            return result
          }

    Vue.prototype.$calcDday = function (targetDate,currentDate) {
      var target = this.$moment(targetDate, "YYYY-MM-DD");
      var base = this.$moment(currentDate).startOf('day');
      
      
      var DdayCount = this.$moment.duration(target.diff(base)).asDays();

      return DdayCount;

      

    };


    



  /***
   * 로컬 데이터 저장
   * 반환은 Array형태로 로 반환
   ***/
    Vue.prototype.$localData = function (key, value) {
      //var _functionName = localData.name;
      var _result;

      //return new Promise(function (resolve, reject) {

        if (this.$nvl(value)) {
          //console.log(`[${_functionName}] ${key} set`);

          if (typeof value === 'object') { //array라면, JSON으로 만들어 로컬스토리지 저장
            localStorage.setItem(key, JSON.stringify(value));
          } else {

            if (this.$isJsonString(value)) { //JSON이라면 그대로 로컬 스토리지 저장
              localStorage.setItem(key, value);
            } else { //그외 라면, 스트링이라고 생각하고,로컬 스토리지 저장
              localStorage.setItem(key, value);
            }
          }

        } else {
          //console.log(`[${_functionName}] ${key} get`);


        }

        var _data = localStorage.getItem(key);


        if (this.$isJsonString(_data)) {
          _result = JSON.parse(_data)
        } else {
          _result = _data
        }

        //resolve(_result)
        return _result
    };


    // CheckMaxString
    Vue.prototype.$CheckMaxString = function (obj, maxNum) {
      var li_str_len = obj.length;
      var li_byte = 0;
      var li_len = 0;
      var ls_one_char = "";
      var ls_str2 = "";
      for (var j = 0; j < li_str_len; j++) {
        ls_one_char = obj.charAt(j);
        if (escape(ls_one_char).length > 4) {
          li_byte += 2;
        } else {
          li_byte++;
        }
        if (li_byte <= maxNum) {
          li_len = j + 1;
        }
      }
      if (li_byte > maxNum) {
        ls_str2 = obj.substr(0, li_len) + "...";
      } else {
        ls_str2 = obj;
      }
      return ls_str2;
    };

    // isEmpty
    Vue.prototype.$isEmpty = function (str) {
      if (typeof str == "undefined" || str == null || str == "") return true;
      else return false;
    };

    //이메일 정규식
    Vue.prototype.$emailValidation = function (data) {
      var regExp =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      if (regExp.test(data)) {
        //통과
        return true;
      } else {
        //올바르지 않음
        return false;
      }
    };


    //ohlcv데이터에 헤더를 붙인다.
    Vue.prototype.$detachSource = function (ohlcv) {
      
      const oIndex = 1,
      hIndex = 2,
      lIndex = 3,
      cIndex = 4,
      vIndex = 5

      let source = []
      source['open'] = []
      source['high'] = []
      source['low'] = []
      source['close'] = []
      source['volume'] = []
      if (ohlcv.length == 0) {
        return source
      }

      ohlcv.forEach((data) => {
        source['open'].push(data[oIndex])
        source['high'].push(data[hIndex])
        source['low'].push(data[lIndex])
        source['close'].push(data[cIndex])
        source['volume'].push(data[vIndex])
      })

      return source
    };



  



    //ohlcv데이터에 헤더를 붙인다.
    //캔들의 순서는 ccxt기본값인 오래된것부터~최신순이어야한다.
    Vue.prototype.$candleDetail = function (candles,patternLength,mode) {
      const self = this;



      
      //원본 30개까지를 가져와확인하여 ma구함
      const average_ma5 = self.$_.meanBy(candles.slice(-5), (itemOHLCV) => itemOHLCV[4]); //패턴중의 ma5 평균
      const average_ma10 = self.$_.meanBy(candles.slice(-10), (itemOHLCV) => itemOHLCV[4]); //패턴중의 ma10 평균
      const average_ma20 = self.$_.meanBy(candles.slice(-20), (itemOHLCV) => itemOHLCV[4]); //패턴중의 ma20 평균
      const average_ma60 = self.$_.meanBy(candles.slice(-60), (itemOHLCV) => itemOHLCV[4]); //패턴중의 ma30 평균
      const average_ma120 = self.$_.meanBy(candles.slice(-120), (itemOHLCV) => itemOHLCV[4]); //패턴중의 ma30 평균

      //const candles_all =candles;

      candles = candles.slice(-(patternLength+1));

      /*
      //패턴추출에 필요한 대상만 가져옴.
      if(self.$nvl(mode,false)){
        //candles = candles.slice(-(patternLength)); 
      }else{
        candles = candles.slice(-(patternLength+1));
      }
      console.log('candles 패턴추출',candles);
      //console.log('모드:',mode);
      */

      
      const average = self.$_.meanBy(candles, (itemOHLCV) => itemOHLCV[4]); //패턴중의 평균
      
      const high = self.$_.maxBy(candles, (itemOHLCV) => itemOHLCV[2])[2];
      const low = self.$_.minBy(candles, (itemOHLCV) => itemOHLCV[3])[3];

      const averageVolume = self.$_.meanBy(
        candles,
        (itemOHLCV) => itemOHLCV[5]
      );
      const highVolume = self.$_.maxBy(candles, (itemOHLCV) => itemOHLCV[5])[5];
      const lowVolume = self.$_.minBy(candles, (itemOHLCV) => itemOHLCV[5])[5];
      /* console.log('average',average);
              console.log('high',high);
              console.log('low',low);
              console.log('averageVolume',averageVolume);
              console.log('highVolume',highVolume);
              console.log('lowVolume',lowVolume);*/

/*
      //완성되어있는 직전 캔들분석 지정
      //const lastCandle = candles[0];
      const lastCandleOpen = candles[0][1];
      //const lastCandleHigh = candles[0][2];
      //const lastCandleLow = candles[0][3];
      const lastCandleClose = candles[0][4];
      const lastCandleChange = this.$calcPercentDiff(lastCandleOpen,lastCandleClose);
*/


        


          
            candles.forEach(function (item, index) {
              //OHLCV
              //12345

              //첫번째는 비교할 수가 없으므로 빈값
              if(index > 0 ){
                candles[index][6] = ''; //6번째 컬럼에 방향성 설정       
                
                candles[index][7] = '';
              }

              //비교가능한 순서부터 종가(4)비교하여 up/down ohlcv컬럼 마지막(6번째)에 추가
              if(index > 0 ){ 
                if(candles[index][4] > candles[index-1][4]){
                  candles[index][6] = '+'; //6번째 컬럼에 방향성 설정 "+:상승"
                }
                if(candles[index][4] < candles[index-1][4]){
                  candles[index][6] = '-'; //6번째 컬럼에 방향성 설정 "-:하락"
                }
                if(candles[index][4] == candles[index-1][4]){
                  candles[index][6] = '='; //6번째 컬럼에 방향성 설정 "=:보합"
                }

                //변동폭 계산
                //candles[index][7] = (self.$calcPercentDiff(candles[index-1][4],candles[index][4])).toFixed(2);
                candles[index][7] = parseFloat(self.$calcPercentDiff(candles[index-1][4],candles[index][4]));
              }
              

              //let datetime = self.$moment(item[0]).format("yyyy-MM-DD H:mm:ss");
                //console.log(index+' : '+datetime, item[4], self.$nvl(item[6],'')+self.$nvl(item[7],''), self.$sumSideText(candles));

            });
      //캔들별 직전 방향성 체크


      //rsi구하기 직전방향성 
      /*const _au = self.$_.meanBy(candles.slice(-5), (itemOHLCV) => itemOHLCV[7]); //패턴중의 ma5 평균

      const isWaitState = this.$_.meanBy(botLogs, function(itemOHLCV) { 
        return itemOHLCV[7]
      });
*/

//console.log('rsi',rsi(14, "close", "binance", "BTC/USDT", "15m", true))

//let ohlcv = await getOHLCV(ex, ticker, interval, isFuture)



if(self.$nvl(mode,false) =='backtest'){
  candles = candles.slice(1,candles.length);
}




      let candleDetail = {

        patternLength:patternLength,
        average: average, //이동 평균가
        average_ma5 : average_ma5,
        average_ma10 : average_ma10,
        average_ma20 : average_ma20,
        average_ma60 : average_ma60,
        average_ma120 : average_ma120,

        high: high, //고가
        low: low, //저가
        averageVolume: averageVolume, //평균거래량
        highVolume: highVolume, //높은 거래량
        lowVolume: lowVolume, //낮은 거래량
        change : self.$_.sumBy(candles, (itemOHLCV) => itemOHLCV[7]), //직전 방향성
        
        lastVolume : candles[candles.length-1][5], //직전 방향성
        lastSide : candles[candles.length-1][6], //직전 방향성
        lastChange : parseFloat(candles[candles.length-1][7]), //직전 방향성
        lastClose : candles[candles.length-1][4], //마지막 종가
        sideFlow : self.$sumSideText(candles), //등락흐름 ++++ 4연속상승 , ---- 4연속하락

        date: self.$moment(candles[candles.length-1][0]).format("yyyy-MM-DD H:mm:ss")








      };
      //console.log('candleDetail.sideFlow',candleDetail.sideFlow);

      //console.log('candles',candles);

      return candleDetail;
    };



    //등락 
    Vue.prototype.$sumSideText = function (candles) {
      const self = this;
      let sumSideText = '';
      candles.forEach(function (item) {
        sumSideText = sumSideText+ self.$nvl(item[6],''); //6번째는 이전에 가공한 side 정보 (up/down)
      });
      return sumSideText;
    };








    //getOHLCV
    //getOHLCV를 최대캔들수 상관없이 가져올 수 있도록함.
    //start ~ end를 넣으면 더 좋겠다...
    Vue.prototype.$getOHLCV = async function (symbol, timeframe, option) {
      let maxlimit = 200; //최대 봉수

      let limitNumber = this.$nvl(option.limit, maxlimit);
      let start = this.$nvl(option.start, undefined);
      let startTimestamp = parseInt(this.$moment(start).format("x"));
      let end = this.$nvl(
        option.end,
        this.$store.state.exchange.milliseconds()
      );
      let endTimestamp = parseInt(this.$moment(end).format("x"));

      // console.log("start", start);
      // console.log("startTimestamp", startTimestamp);
      // console.log("end", end);
      // console.log("endTimestamp", endTimestamp);

      //내부에서 순환용도로 쓸 리밋
      let limit = limitNumber;
      //console.log("limit", limit);

      let isCountMode = true;
      if (!this.$nvl(option.limit, false)) {
       // console.log("기간내 모든 갯수 가져오기 모드");
        isCountMode = false;
      } else {
       // console.log("최근 갯수로 가져오기 모드");

        if (limitNumber > 399) {
          limitNumber = limitNumber + 1;
        }
      }

      //let symbol = "BTC/USDT";
      //let timeframe = "1h"; //기준
      //let since = undefined;
      //let params = {};

      //let self = this;

      if (this.$store.state.exchange.has["fetchOHLCV"]) {
        //let since = this.$store.state.exchange.milliseconds () - 2186400000 // -1 day from now
        let since = this.$nvl(startTimestamp, undefined); // -1 day from now
        if (isCountMode) {
          since = undefined;
        }
        //let end =  this.$store.state.exchange.parse8601 ('21-03-01T00:00:00Z')
        // alternatively, fetch from a certain starting datetime
        //let since =  this.$store.state.exchange.parse8601 ('2018-01-01T00:00:00Z')
        //console.log("since", since);
        //console.log(this.$store.state.exchange.milliseconds());
        let allTrades = [];
        let lastItemTimestamp = 0; //마지막 아이템의 타임스탬프

        //분봉 최대 요청치가 200이므로, 200개 이상 을 기준으로 재신청 가능하도록 구성
        if (limitNumber > maxlimit) {
          limit = maxlimit;
        }
        let self = this;

        //console.log("allTrades", allTrades.length);
        //console.log("limitNumber", limitNumber);
        //console.log("lastItemTimestamp first", lastItemTimestamp);

        //가져온 아이템의 마지막 타임스탬프가 지정된 타임스탬프보다 작으면, 한번더 불러와야함.
        while (
          (lastItemTimestamp < endTimestamp && !isCountMode) || //기간가져오기 조건
          (isCountMode && allTrades.length < limitNumber) //카운터모드 조건
        ) {
          //    while (lastItemTimestamp < endTimestamp || allTrades.length < limitNumber && since < this.$store.state.exchange.milliseconds () ) {
          await this.$sleep(this.$store.state.exchange.rateLimit); // milliseconds
          //await this.$sleep(1000); // milliseconds

          //const symbol = undefined // change for your symbol

          //console.log("lastItemTimestamp in", lastItemTimestamp);

          //console.log(symbol, timeframe, since, limit);
          const trades = await this.$store.state.exchange.fetchOHLCV(
            symbol,
            timeframe,
            since,
            limit
          );
          //console.log(allTrades.length + " / " + limitNumber);
          //console.log(trades);

          //console.log('마지막 시점',self.$moment(trades[trades.length-1][0]).format("yyyy-MM-DD H:mm:ss"));

          if (trades.length) {
            if (!isCountMode) {
              lastItemTimestamp = trades[trades.length - 1][0]; //마지막 아이템의 타임스탬프
              since = lastItemTimestamp; //다음에 호출하게 된다면, 마지막 아이템의 타임스탬프가 곧 since 기준

              //console.log("endTimestamp", endTimestamp);

              //목표하는 end 시점이 아니라면 한번더 진행해야 함.
              //마지막 시점의 아이템이 종료시점보다 같거나 크다면, 일부만 가져오고, 종료

              /*
              console.log("비교", lastItemTimestamp, endTimestamp);
              console.log(
                "비교",
                self.$moment(lastItemTimestamp).format("yyyy-MM-DD H:mm:ss"),
                self.$moment(endTimestamp).format("yyyy-MM-DD H:mm:ss")
              );*/

              //중복방지를 위해 첫번째 아이템 삭제
              //trades.splice(0, 1);

              if (lastItemTimestamp >= endTimestamp) {
                //console.log("마지막 불러온 시점 > 지정한 종료 시점");
                //console.info("그만불러와야함 있는 걸로 추려서 저장하고 스탑.");

                trades.forEach(function (subItem) {
                  if (subItem[0] <= endTimestamp) {
                    //작거나 같은것만 넣기 넣기
                    allTrades.push(subItem);
                  }
                });
/*
                console.warn(
                  "아이템 범위: ",
                  `${this.$moment(allTrades[0][0]).format(
                    "yyyy-MM-DD H:mm:ss"
                  )} 
                            ~ ${this.$moment(
                              allTrades[allTrades.length - 1][0]
                            ).format("yyyy-MM-DD H:mm:ss")}`
                );
*/
                break;
              }
              //목표하는 end 시점이 아니라면 한번더 진행해야 함.
              //마지막 시점의 아이템이 종료시점보다 같거나 크다면, 일부만 가져오고, 종료

              //종료시점보다 작다면 전체 넣고, 한번더 실행
              since = trades[trades.length - 1][0];
              allTrades = allTrades.concat(trades);
            }

            if (isCountMode) {
              //최대 호출 가능한 수에서 나머지 먼저 구하기
              let countRemainder = limitNumber % maxlimit;
              console.log("먼저 가져와야할 수", countRemainder);

              //최대 호출 가능한 수를 반복해서 가져와야할 횟수 구하기
              let loopCount = Math.floor(limitNumber / maxlimit);
              console.log("추가로 반복해서 가져와야할 횟수", loopCount);

              if (countRemainder > 0 && allTrades.length < 1) {
                allTrades.splice(0, 1);

                let tmp_trades = self.$_.reverse(trades);

                tmp_trades.forEach(function (subItem, index) {
                  if (countRemainder > index) {
                    //작거나 같은것만 넣기 넣기
                    allTrades.push(subItem);
                  }
                });

                /*console.warn(
                  "아이템 범위: ",
                  `${self
                    .$moment(allTrades[0][0])
                    .format("yyyy-MM-DD H:mm:ss")} 
                                  ~ ${self
                                    .$moment(allTrades[allTrades.length - 1][0])
                                    .format("yyyy-MM-DD H:mm:ss")}`
                );
                */

                //console.log(allTrades);
              }

              if (countRemainder < allTrades.length) {
                //중복방지를 위해 첫번째 아이템 삭제
                //trades.splice(0, 1);
              }

              //종료시점보다 작다면 전체 넣고, 한번더 실행
              since = self.$_.last(trades)[0];
              allTrades = allTrades.concat(trades);

             /* console.warn(
                "아이템 범위: ",
                `${self.$moment(allTrades[0][0]).format("yyyy-MM-DD H:mm:ss")} 
                                  ~ ${self
                                    .$moment(allTrades[allTrades.length - 1][0])
                                    .format("yyyy-MM-DD H:mm:ss")}`
              );

              console.log(allTrades);
              */

              //console.log(allTrades);

              if (trades.length < maxlimit) {
                console.log("끝1");
                break;
              }

              /*
  
                              //종료시점보다 작다면 전체 넣고, 한번더 실행
                              since = trades[trades.length - 1][0];
                              allTrades = allTrades.concat (trades)
                              
                              console.log(trades);
  
                              console.warn(
                                "아이템 범위: ",
                                `${this.$moment(allTrades[0][0]).format("yyyy-MM-DD H:mm:ss")} 
                                ~ ${this.$moment(allTrades[allTrades.length-1][0]).format("yyyy-MM-DD H:mm:ss")}`);
  
  
                              */
            }
          } else {
            console.log("추가로 받아올 결과가 없어서 끝");
            break;
          }

          //카운팅모드에서 갯수 차감체크위해 체크
          if (isCountMode) {
            //갯수로 구할때 필요함.
            limit = limitNumber - maxlimit;
          }

          //console.log("limit", limit);
        }

        allTrades = self.$_.uniqBy(allTrades, 0);
        //console.log(allTrades);
/*

        allTrades.forEach(function (item) {
          console.log(self.$moment(item[0]).format("yyyy-MM-DD H:mm:ss"));
        });
*/

        this.dataOHLCV = allTrades;

        return allTrades;
      }
    };
    //getOHLCV
    //getOHLCV를 최대캔들수 상관없이 가져올 수 있도록함.
    //start ~ end를 넣으면 더 좋겠다...




    Vue.prototype.$calAmount = async function (usdt_balance,cur_price) {
      const portion = 0.1 
      const usdt_trade = usdt_balance * portion
      const amount = Math.floor((usdt_trade * 100000)/cur_price) / 1000000
      return amount
    };
    




    // externalLink
    Vue.prototype.$externalLink = async function (url) {
      //return await shell.openExternal(url);
      return url
    };

    // buttonMinimize
    Vue.prototype.$buttonMinimize = function () {
      //ipcRenderer.send("minimize");
      console.log('minimize');
    };
    // buttonClose
    Vue.prototype.$buttonClose = function () {
      //ipcRenderer.send("close");
      console.log('close');
    };
    // buttonClose
    Vue.prototype.$buttonRelaunch = function () {
      //ipcRenderer.send("relaunch");
      console.log('buttonRelaunch');
    };
    // buttonClose
    Vue.prototype.$buttonReload = function () {
      console.clear();
      this.$localData('botLogs','[]');


      //2022-09-16 추가
      var backup = {};
      for (let i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);
        var value = localStorage.getItem(key);
        let isCanSave = true;
        
        if(key.includes( 'botLogs')){ //로그는 제외
          isCanSave = false;
        }
        if(key.includes( 'candleList')){ //캔들도 제외
          isCanSave = false;
        }
        if(key.includes( 'userOrder')){ //오더도 제외
          isCanSave = false;
        }

        if(isCanSave){
          backup[key] = escape(encodeURIComponent(value));      
        }

      }
      var reload_json = JSON.stringify(backup);
      var reload_backup = JSON.parse(reload_json);
      
      localStorage.clear();
      for (var reload_key in reload_backup){
        var reload_value = decodeURIComponent(unescape(reload_backup[reload_key]));
        window.localStorage.setItem(reload_key, reload_value);
      }
      window.localStorage.setItem('is_refresh',1);


      //2022-09-16 추가





      
      setTimeout(() => {
       // ipcRenderer.send("reload");
        console.log('reload');
        window.location.reload();
      }, 1000);
    };
    // buttonClose
    Vue.prototype.$fileSave = function (data) {
      //console.log('filesave1',data);
      //ipcRenderer.send("filesave",data);
      console.log('filesaave',data);
    };
    Vue.prototype.$getVersion = async function () {
      //const self = this;
      //console.log('filesave1',data);      
    this.$localData('systemInfo','');
//    let result = await ipcRenderer.invoke('getversion');
let result = false;
    //console.log('getversion',result);
    //this.$localData('systemInfo',result);

    
    if(result){
      this.$store.commit("UPDATE_SYSTEM_INFO", result);




    }

    };
    Vue.prototype.$fileLoad = function () {


      
      var f = document.querySelector('#form_restore_settings').target.files[0];
      const reader = new FileReader(); 
      reader.readAsText(f, 'utf-8');
      
      console.log(reader);


    };


    Vue.prototype.$saveSettings = async function(_systemOption) {
      const self = this;



      
            //로컬에 저장
            let systemOption = this.$localData('systemOption',_systemOption);
            //store에 설정저장
            await this.$store.dispatch("UPDATE_SETTINGS", systemOption);
      
            let alertDialogInfo = {
              timeout: 2000,
              persistent:true,
              //emoji: "😆",
              title: self.$t('global_option_saved'),//"저장되었습니다.",
              firstLineText: self.$t('global_option_saved_txt'),//"프로그램을 재구동합니다.",
              //secondLineText: "다시 확인해주세요.",
              // thirdLineText: "셋째줄",

              };
            this.$store.dispatch("openAlertDialog", alertDialogInfo);
/*
                          setTimeout(() => {
                              self.$buttonRelaunch();
                          }, 2000);
                          */
            setTimeout(() => {
              self.$getBotOptions();

              console.log(self);    
              
              window.location.reload();

               //self.$buttonRelaunch();
               //self.$buttonReload();
                  //location.href = '/';
                  /*
                  self.$router.push(`/`);
                  self.$store.dispatch("GET_BALANCE");
                  self.$store.dispatch("GET_TICKER");
                  */
            }, 300);




    }



    // buttonClose
    Vue.prototype.$resetSettings = function () {
      const self = this;
      
setTimeout(() => {
  
  let alertDialogInfo = {
    timeout: 2000,
    persistent:true,
    //emoji: "😆",
    title: self.$t('global_dialog_reset_title'),
    firstLineText: self.$t('global_dialog_reset_firstLineText'),
    //secondLineText: "다시 확인해주세요.",
    // thirdLineText: "셋째줄",

    };
    this.$store.dispatch("openAlertDialog", alertDialogInfo);
}, 300);

localStorage.clear();





            
            setTimeout(() => {
              window.location.reload();
              //self.$buttonRelaunch();
              //location.href = '/';
              /*
              self.$router.push(`/`);
              self.$store.dispatch("GET_BALANCE");
              self.$store.dispatch("GET_TICKER");
              */
            }, 300);
    };



    // buttonClose symbol,side,type,msg,open_id,close_id
    Vue.prototype.$addLog = async function (obj) {
      let botLogs = this.$nvl(this.$localData('botLogs'),[]);
      
      if(this.$nvl(obj) && obj.symbol && obj.type && obj.msg){
        let logContent = {
          'symbol':obj.symbol, 
          'side':this.$nvl(obj.side,''), 
          'type':obj.type, 
          'msg': obj.msg,
          'amount' : this.$nvl(obj.amount,''),
          'open_id': this.$nvl(obj.open_id,''),
          'open_price': this.$nvl(obj.open_price,''),
          'close_id': this.$nvl(obj.close_id,''),
          'close_price': this.$nvl(obj.close_price,''),
          'clear': this.$nvl(obj.clear,false),
          'time': this.$moment().format("yyyy-MM-DD H:mm:ss"),
          //'payload':obj.payload,


          'status': this.$nvl(obj.status,''),
        }
        botLogs.unshift(logContent)

        botLogs = botLogs.slice(0,100)


        this.$localData('botLogs',botLogs);
        this.$store.commit("UPDATE_LOG", botLogs);
        //this.$store.botlog = botLogs;

        return logContent
        
      }else{

        return false
      }
    };


    
    Vue.prototype.$getLog = function (obj) {
      let botLogs = this.$nvl(this.$localData('botLogs'),[]);
      if(this.$nvl(obj,false)){
        botLogs = this.$_.filter(botLogs, obj);        
      }
      return botLogs
    }


    Vue.prototype.$clearLog = async function () {
      this.$localData('botLogs','');
      this.$store.commit("UPDATE_LOG", []);
      
      this.$store.botlog = [];
    }

    Vue.prototype.$diffTimeNow = function (time) {
      const latestOrderTime = time;
      const t1 = this.$moment(latestOrderTime); //마지막 오더 시간
      const t2 = this.$moment(); //현재 시간
      const lastAgo = this.$setFloat(this.$moment.duration(t2.diff(t1)).asMinutes(),1); //지난 분
      return lastAgo
    }

    Vue.prototype.$diffTimeAgo = function (time,type) {
      const latestOrderTime = time;
      let _type = 'seconds';
      if(this.$nvl(type,false)){
        _type = type
      }
      const t1 = this.$moment(latestOrderTime); //마지막 오더 시간
      const t2 = this.$moment(); //현재 시간

      let lastAgo = this.$setFloat(this.$moment.duration(t2.diff(t1)).asMilliseconds()/1000,2); //지난 초

      if(_type == 'ms'){
        lastAgo = this.$setFloat(this.$moment.duration(t2.diff(t1)).asMilliseconds(),2); //지난 밀리초
      }
      if(_type == 'minutes'){
        lastAgo = this.$setFloat(this.$moment.duration(t2.diff(t1)).asMinutes(),2); //지난 분
      }
      if(_type == 'hours'){
        lastAgo = this.$setFloat(this.$moment.duration(t2.diff(t1)).asHours(),2); //지난 시간
      }
      if(_type == 'days'){
        lastAgo = this.$setFloat(this.$moment.duration(t2.diff(t1)).asDays(),2); //지난 일
      }


      return lastAgo
    }



    // buttonMinimize
    Vue.prototype.$getMarketList = async function () {
      try {


         // const pairs = await state.exchange.loadMarkets();
          //commit('getMarketsPair', pairs)

          //console.warn('getMarketsPair',pairs);

          let marketList = await this.$store.state.exchange.loadMarkets();
          //console.warn('getMarketsPair',marketList);


          marketList = Object.values(marketList);
          marketList = this.$_.filter( marketList , function(item) { return item.quote == 'USDT' });

          this.$store.commit("UPDATE_MARKET_LIST", marketList);
          this.$localData('marketList',marketList)


      } catch (error) {
          console.log(error)
      }
    };




    // buttonMinimize
    Vue.prototype.$getCoinInfo = function (symbol) {

      let marketList = this.$store.state.marketlist;
      marketList = Object.values(marketList);
      let coinInfo = this.$_.find( marketList , function(item) { return item.symbol == symbol });

      return coinInfo
    };



    // buttonMinimize
    Vue.prototype.$getBotOptions = function () {
      const self = this;

      const symbols = self.$localData('systemOption').symbols;
            
      //로컬의 봇옵션을 가져옴
      let botOption = self.$nvl(this.$localData('botOption'),[]);
      



      //console.log('_symbol botOption',botOption);



      
      //대상 심볼들 탐색
      self.$_.forEach(symbols, function (symbol) {
        //옵션에 대상 심볼이 존재하는지 확인
        const _symbol = self.$_.find(botOption, function(bot_option) { 
                        return bot_option.symbol == symbol});
        //console.log('_symbol',_symbol);

        //없을경우 초기화하여 생성
        if(!_symbol){
          //console.log('_symbol xxxx',symbol);
          const _buy_option = self.$initBotOption(symbol,'buy');
          const _sell_option = self.$initBotOption(symbol,'sell');
          //console.log('_symbol _buy_option',_buy_option);
          //console.log('_symbol _sell_option',_sell_option);

          botOption.push(_buy_option);
          botOption.push(_sell_option);
        }

      });





      this.$localData('botOption',botOption)
      return botOption;
    };


    Vue.prototype.$initBotOption = function (symbol,side,is_preset) {

      //console.log('_symbol initBotOption',symbol);

      let sideLabel = '롱';
      if(side == 'sell'){
        sideLabel = '숏';
      }
      /*
      let defaultPattern = '---+';
      if(side == 'sell'){
        sideLabel = '숏';
        defaultPattern = '+++-';
      }*/

      let  initBotInfo = {
                        id : symbol + '-' + side,
                        name : symbol+' '+sideLabel+' 전략',
                        symbol: symbol, //코인 종류
                        side: side, //방향 buy/sell
                        catch:{
                          "timeframe": "5m",
                          "count": 4,
                          "pattern": "*",
                          "candle_min": "",
                          "candle_max": "",
                          "candle_last_min": "",
                          "candle_last_max": "",
                          //"candle_min": 0.7,
                          //"candle_max": "",
                          //"candle_last_min": "",
                          //"candle_last_max": 0.15,
                          "entrybase": "bot_1",
                          "ordertype": "market",
                          "fundingtype": "balance",
                          "ma5": "down",
                          "ma10": "down",
                          "ma20": "down",
                          "ma60": "",
                          "ma120": "",
                          "_diff_position_per": 0,
                          "_ordertype_profit_high": null,
                          "_is_trail_position": false,
                          "pattern_length": 5,
                          "pattern_1": "+",
                          "pattern_2": "-",
                          "pattern_3": "-",
                          "pattern_4": "-",
                          "pattern_5": "*",
                          "pattern_type": "v2",
                          "ordertype_profit": "market",
                          "rsi1_length": 14,
                          "rsi2_length": "",
                          "rsi1_min": "",
                          "rsi2_min": "",
                          "rsi1_max": 25,
                          "rsi2_max": "",
                          "ma30": ""
                          }, 
                                            
                          limit: {
                            "total": 5,
                            "time": 15
                          },
                          "profit": "1",
                          "profit_position": "100",
                          "profit_time": "",
                          "profittype": "percent",
                          "profit_amount": 100,
                          "stoploss": "-5",
                          "stoploss_position": -70,
                          "amount": "0.01",
                          "amounttype": "normal",
                          "synctype": false,
                          "active": {
                              "catch": false,
                              "profit": true,
                              "stoploss": true,
                              "burn": true,
                              "water": false,
                              "profit_position": false,
                              "stoploss_position": false
                          },
                  };
                  if(side == 'sell'){
                    initBotInfo.catch.pattern_1="-";
                    initBotInfo.catch.pattern_2="+";
                    initBotInfo.catch.pattern_3="+";
                    initBotInfo.catch.pattern_4="+";
                    initBotInfo.catch.pattern_5="*";

                    
                    initBotInfo.catch.rsi1_min=75;
                    initBotInfo.catch.rsi2_min="";
                    initBotInfo.catch.rsi1_max="";
                    initBotInfo.catch.rsi2_max="";

                    initBotInfo.catch.ma5="up";
                    initBotInfo.catch.ma10="up";
                    initBotInfo.catch.ma20="up";
                  }


                  //프리셋은 코인인포를가져오지않음. S
                if(!this.$nvl(is_preset,false)){
                  const coinInfo =this.$getCoinInfo(symbol);

                  //console.log('_symbol coinInfo 1',coinInfo);

                  initBotInfo.amount = coinInfo.limits.amount.min;

                  /*
                  if(initBotInfo.amount < coinInfo.limits.amount.min){
                    initBotInfo.amount = coinInfo.limits.amount.min;
                  }
                  if(initBotInfo.amount > coinInfo.limits.amount.max){
                    initBotInfo.amount = coinInfo.limits.amount.max;
                  }
                  */
                }
                //프리셋은 코인인포를가져오지않음. E
          
                //console.log('_symbol initBotInfo',initBotInfo);



          return initBotInfo

    };

    Vue.prototype.$getBotInfo = function (id) {
      return this.$_.find(this.$store.state.botOption, {'id': id});
    }




    Vue.prototype.$calcRsi = function (candles,rsiLength) {
      
      let source = this.$detachSource(candles);
      let rsiInput = {
        values: source['close'],
        period: rsiLength,
      }
      //console.log('rsiInput',rsiInput);


      const resultRSI = RSI.calculate(rsiInput)

      return resultRSI
      //console.log('resultRSI',resultRSI);


    };



  },

  
};
