import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

//공통 함수 - 본문에서 불러와쓸 수 있음.
import common from "./assets/js/common";
Vue.use(common);
//공통 함수 - 본문에서 불러와쓸 수 있음.


//공통 moment 함수 - 본문에서 불러와쓸 수 있음.
import VueMoment from "vue-moment";
import moment from "moment";
import 'moment-timezone';
//moment.locale("ko");


import i18next from 'i18next';
if(i18next.locale =='ko'){
  moment.locale("ko");
}

moment.tz.setDefault("Asia/Seoul"); 
window.timezone = moment.tz.guess();


Vue.use(VueMoment, { moment });
//공통 moment 함수 - 본문에서 불러와쓸 수 있음.

import axios from "axios";
Vue.prototype.$axios = axios;


Vue.prototype.$rootApp = App;


/*
import i18next from 'i18next';
//console.log(i18next);
i18next.init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  debug: true,
  resources: {
    en: {
      translation: {
        "key": "hello world"
      }
    },
    ko: {
      translation: {
        "key": "안녕!"
      }
    }
  }
}, function(err, t) {
  // initialized and ready to go!

  i18next.changeLanguage('ko', (err, t) => {
    if (err) return console.log('something went wrong loading', err);
    t('key'); // -> same as i18next.t
  });

  console.log('i18next',i18next.t('key'));
  console.log('err',err);
  console.log('t',t);

  //document.getElementById('output').innerHTML = i18next.t('key');
});

*/






//import timesync from "timesync";

//console.log(axios);


/*
// create a timesync client
var ts = timesync.create({
  peers: 'https://api.bybit.com/v2/public/time',
  interval: 10000
});
*/



//Component Registration
//폴더내에 있는 vue component 를 global 컴포넌트로 만들기 S
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
const requireComponent = require.context("./components/", false, /[\w-].vue$/);
requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
  );
  // Register component globally
  Vue.component(componentName, componentConfig.default || componentConfig);
});
//Component Registration
//폴더내에 있는 vue component 를 global 컴포넌트로 만들기 E


import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });


import VueCrontab from 'vue-crontab'
import i18n from './i18n'
Vue.use(VueCrontab)



Vue.mixin({
  methods: {
    goUrl(url, params) {
      return this.$router.push(url, params);
    },
    goBack() {
      return this.$router.go(-1);
    },
    stripHTML(html) {
      return html.replace(/(<([^>]+)>)/gi, "");
    },
  },
});




//import NtpTimeSync from 'ntp-time-sync';
//console.log(NtpTimeSync);



/*
let timer_ticker = 1000;
console.log(timer_ticker);
*/

window.vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),

  computed: {
    userdata() {
      return this.$store.state.user.userdata;
    },
  },

  methods: {

  },

  i18n,

  created() {    
    const self = this;


    //아직 멀티랭귀지 오픈 전이기때문에 한국어만 표시
    this.$store.state.disable_multilang = false;
    
    //this.$localData('lang','ko'); //요기를 en, ko로 바꾸면 강제로 고정됨
    //this.$i18n.locale= 'ko';
    
    //아직 멀티랭귀지 오픈 전이기때문에 한국어만 표시

    //console.log('lang',"_thisLang = "+_thisLang);   

    let _thisLang = this.$localData('lang');
    if(!_thisLang){
      _thisLang = navigator.language;
    
      if(_thisLang == 'ko-KR'){
        _thisLang = 'ko';
      }

      _thisLang = this.$localData('lang',_thisLang);
    }
    this.$i18n.locale= _thisLang;



    
/*

if(_thisLang == 'ko-KR'){
  localStorage.setItem('lang','ko');
}
*/




    /*
if(_thisLang == 'ko'){
  //언어따라 변경필요한 부분
  this.$localData('remote_url','https://remote.crypto25.net/bots/');
  this.$localData('info_url','https://stats.crypto25.net/info/');
}else{
  this.$localData('remote_url','https://remote.crypto25.org/bots/');
  this.$localData('info_url','https://crypto25.org/info/');
}
*/



    //언어설정 E




    const _systemInfo = this.$localData('systemOption');
    if(_systemInfo){
    
          const isSingleSymbol = this.$nvl(_systemInfo.server_url,false);
        
          if(!isSingleSymbol){

            let is_possible = confirm('기존 버전의 설정이 발견되었습니다.\n이 버전은 기존 버전과 호환되지 않습니다.\n\n기존버전의 설정을 모두 초기화하고 현재 버전으로 진행 하시겠어요?');
                          if(is_possible){
                            this.$resetSettings();
                          }else{
                            this.$buttonClose();
                          }
             return 

          }
    

          //console.log(_systemInfo);
          //console.log(isSingleSymbol);
    }
    



    
    //this.$getVersion();
    
    //서버 정보를 가장 먼저 가져온다.
//    this.$getServerInfo();


    //console.log(this.$localData('systemOption'));

    //시스템 설정 불러오기
    let systemOption = this.$localData('systemOption');

    if(this.$nvl(systemOption,false)){
      
        this.systemOption = systemOption;

        if(!systemOption.performance_option){
          systemOption.performance_option = '';
        }
        if(!systemOption.watch_option){
          systemOption.watch_option = '';
        }
        if(!systemOption.bot_clear_option){
          systemOption.bot_clear_option = '';
        }



      }else{
        this.systemOption = {
          server_url : window.server_url,
          stats_url : window.stats_url,
          
          bot_clear_option : "",
          position_option : "",
          starting_option : 'auto',
          performance_option : '',
          watch_option : "1",
          lang: _thisLang,

          "exchange": "bybit",
          //"symbol": "BTC/USDT",
          "symbols": ["BTC/USDT"],
          "api": {
            "apiKey": "",
            "secret": ""
          },
          "future": true,
          "testmode": false
        };
      }
    //console.log('시스템설정 로드 완료',this.systemOption);
    this.$store.dispatch("UPDATE_SETTINGS", this.systemOption);
    this.$localData('systemOption',this.systemOption);




    


  
    //봇 기본 로드 완료
    let localBotList = this.$nvl(this.$localData('botList'),false);
    if(!localBotList){
      localBotList = {
        open:[],
        close:[],
        trail:[],
      };
      this.$localData('botList',localBotList)
    }
    this.$store.commit("UPDATE_BOT_LIST",localBotList);

    

    let botLogs = this.$nvl(this.$localData('botLogs'),[]);
    //console.log('로그 로드 완료',botLogs);
    this.$store.commit("UPDATE_LOG",botLogs);

    
    

//





    
    setTimeout(async () => {
        await this.$getMarketList();



        //봇 설정 불러오기
        let botOption = this.$localData('botOption');
        if(this.$nvl(botOption,false)){
          this.botOption = botOption;



          //로컬 표시에서 숏이 롱으로 모두 보이는 현상 픽스 S
            let activeSideNameFix = false;
            self.$_.forEach(botOption, function (bot_option,index) {
              if(bot_option.side == 'sell' && self.$_.includes(bot_option.name,'롱')){
                activeSideNameFix = true;
                console.log('getBotOptions',bot_option,activeSideNameFix);
                botOption[index].name = (botOption[index].name).replace(/롱/g,"숏");
                console.log('(botOption[index].name)',(botOption[index].name));
              }
            });
            if(activeSideNameFix){
              this.botOption = botOption;
              this.$localData('botOption',botOption);
            }
            //로컬 표시에서 숏이 롱으로 모두 보이는 현상 픽스 E




        }else{
          this.botOption = this.$getBotOptions();
        }

        
        //봇에 시장대응 트랜지션옵션 없다면, 기본값 넣어줌..
        self.$_.forEach(self.botOption, function (botOptionItem) {

          if(!self.$nvl(botOptionItem.transition_1,false)){
            botOptionItem.transition_1 = '';
            botOptionItem.transition_1_base = 'active_bot';
            botOptionItem.transition_1_condition = 'up';
            botOptionItem.transition_1_preset = 'PRESET-'+botOptionItem.side+'-1';
            botOptionItem.transition_1_value = 10;
            botOptionItem.transition_1_amount = null;
          }

          if(!self.$nvl(botOptionItem.transition_2,false)){
            botOptionItem.transition_2 = '';
            botOptionItem.transition_2_base = 'active_bot';
            botOptionItem.transition_2_condition = 'up';
            botOptionItem.transition_2_preset = 'PRESET-'+botOptionItem.side+'-1';
            botOptionItem.transition_2_value = 10;
            botOptionItem.transition_2_amount = null;
          }

        });
        
        //봇에 시장대응 트랜지션옵션 없다면, 기본값 넣어줌..



        //console.log('봇 옵션 로그 완료',this.botOption);
        this.$store.dispatch("UPDATE_BOT", this.botOption);
        

        //프리셋 설정 불러오기 S
        let presetOption = this.$localData('presetOption');
        if(!this.$nvl(presetOption,false)){
          let presetItemBuy = this.$initBotOption('preset','buy',true);
          let presetItemSell = this.$initBotOption('preset','sell',true);

          let presetItemBuy_1 = JSON.parse(JSON.stringify(presetItemBuy));
          let presetItemBuy_2 = JSON.parse(JSON.stringify(presetItemBuy));
          let presetItemBuy_3 = JSON.parse(JSON.stringify(presetItemBuy));
          presetItemBuy_1.no = 1; 
          presetItemBuy_2.no = 2;
          presetItemBuy_3.no = 3;
          presetItemBuy_1.id = "PRESET-buy-"+ presetItemBuy_1.no;         
          presetItemBuy_2.id = "PRESET-buy-"+ presetItemBuy_2.no;    
          presetItemBuy_3.id = "PRESET-buy-"+ presetItemBuy_3.no;
          presetItemBuy_1.name = "프리셋 롱 전략 "+ presetItemBuy_1.no;         
          presetItemBuy_2.name = "프리셋 롱 전략 "+ presetItemBuy_2.no;
          presetItemBuy_3.name = "프리셋 롱 전략 "+ presetItemBuy_3.no;



          let presetItemSell_1 = JSON.parse(JSON.stringify(presetItemSell));
          let presetItemSell_2 = JSON.parse(JSON.stringify(presetItemSell));
          let presetItemSell_3 = JSON.parse(JSON.stringify(presetItemSell));
          presetItemSell_1.no = 1;
          presetItemSell_2.no = 2;
          presetItemSell_3.no = 3;
          presetItemSell_1.id = "PRESET-sell-"+ presetItemSell_1.no;         
          presetItemSell_2.id = "PRESET-sell-"+ presetItemSell_2.no;
          presetItemSell_3.id = "PRESET-sell-"+ presetItemSell_3.no;
          presetItemSell_1.name = "프리셋 숏 전략 "+ presetItemBuy_1.no;         
          presetItemSell_2.name = "프리셋 숏 전략 "+ presetItemBuy_2.no; 
          presetItemSell_3.name = "프리셋 숏 전략 "+ presetItemBuy_3.no;

          presetOption = this.$localData('presetOption',{
            buy : [presetItemBuy_1,presetItemBuy_2,presetItemBuy_3],
            sell : [presetItemSell_1,presetItemSell_2,presetItemSell_3],
          });
        }
        this.$store.dispatch("UPDATE_PRESET", presetOption);
        //프리셋 설정 불러오기 E

/*
        const is_refreshed = this.$nvl(window.localStorage.getItem('is_refresh'),false);

        if(!is_refreshed){
          
              if(this.$store.state.systemOption.starting_option == 'on'){
                this.$globalBotOption({
                  active : {catch:true}
                });
              }
              if(this.$store.state.systemOption.starting_option == 'off'){
                this.$globalBotOption({
                  active : {catch:false}
                });
              }
        }else{
          window.localStorage.removeItem('is_refresh');          
        }
        


          this.$setWebSocket();  
          */
      }, 1000);




  }
}).$mount('#app')

//console.log('platform init',window.vm);








// let wakeLock = null;

// if ('wakeLock' in navigator) {
//   console.log('wakeLock : Screen Wake Lock API supported!');
//   wakeLock = navigator.wakeLock.request('screen');
//   console.log('wakeLock : ',wakeLock);
// } else {
//   console.log('Wake lock is not supported by this browser.');
// }
